import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useStores } from "stores/index";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import FormWindow from "shared/ui/FormWindow";
import styles from "./staffOneEditPhotoAltGallery.module.scss";
import Modal from "shared/ui/Modal";
import PhotoAltGalleryUploadSection from "./PhotoAltGalleryUploadSection";
import PhotoAltGalleryEditSection from "./PhotoAltGalleryEditSection";
import PhotoAltGalleryViewSection from "./PhotoAltGalleryViewSection";
import PhotoAltGalleryManageBox from "./PhotoAltGalleryManageBox";
import PhotoAltGalleryThumbnails from "./PhotoAltGalleryThumbnails";

type StaffOneEditPhotoAltGalleryProps = {
  loadedImages: { hash: string; isMain: number; src: string }[];
  imageIndex: number;
  setImageIndex: (imageIndex: number) => void;
  galleryState: string;
  setGalleryState: (galleryState: string) => void;
  userId: string;
};

export interface IFile extends File {
  preview?: string;
  path?: string;
}

const StaffOneEditPhotoAltGallery = ({
  loadedImages,
  imageIndex,
  setImageIndex,
  galleryState,
  setGalleryState,
  userId
}: StaffOneEditPhotoAltGalleryProps) => {
  const cropperRef = useRef(null);
  const { staffOneEditStore } = useStores();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<
    "confirmDelete" | "unsavedChanges" | null
  >(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [editingFromViewing, setEditingFromViewing] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState<string | null>(null);

  // Функция для сохранения фотографии
  const handleSaveData = async () => {
    const imgData = cropperRef.current?.getCropImage();

    const file = imgData
      ? await staffOneEditStore.getDataURLtoFile(
          imgData,
          files[0]?.path || "newImage.jpg"
        )
      : files[0];
    staffOneEditStore.setUploadPhoto(
      loadedImages.length === 0 || loadedImages[imageIndex]?.isMain
        ? userId
        : "",
      file
    );

    setImageIndex(imageIndex ?? 0);
    setShowModal(false);
    setGalleryState("closed");
  };

  // Функция для закрытия окна редактирования без сохранения
  const handleCloseEditing = () => {
    setGalleryState("viewing");
    setEditingFromViewing(false);
  };

  // Функция для удаления фотографии
  const handleDeleteClick = () => {
    setPhotoToDelete(loadedImages[imageIndex]["hash"]);
    setModalType("confirmDelete");
    setShowModal(true);
  };

  // Получение названия изображения
  const imageName =
    galleryState === "editing"
      ? editingFromViewing
        ? loadedImages[imageIndex]?.src.split("/").pop() || "Без имени"
        : files[0]?.name || "Без имени"
      : loadedImages[imageIndex]?.src.split("/").pop()?.split("?")[0] ||
        "Без имени";

  // Очистка URL- объектов при размонтировании компонента
  useEffect(() => {
    return () => {
      files.forEach((file) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, [files]);

  return (
    <>
      <Formik
        initialValues={loadedImages}
        validateOnBlur
        validateOnChange
        onSubmit={() => {
          return;
        }}
      >
        {({ setFieldValue, dirty }) => {
          // Используется хук UseDropzone для обработки загрузки файлов
          const { getRootProps, getInputProps, open } = useDropzone({
            accept: {
              "image/*": []
            },
            onDrop: (acceptedFiles) => {
              setFiles(
                acceptedFiles.map((file) =>
                  Object.assign(file, {
                    preview: URL.createObjectURL(file)
                  })
                )
              );
              setFieldValue("isMain", true);
              setGalleryState("editing");
            }
          });

          // Функция для закрытия окна с проверкой на несохранённые изменения
          const closeWindow = () => {
            if (dirty) {
              setModalType("unsavedChanges");
              setShowModal(true);
            } else {
              setGalleryState("closed");
            }
          };

          return (
            <FormWindow
              title={
                galleryState === "uploading"
                  ? "Загрузка фото"
                  : galleryState === "editing"
                  ? "Редактирование фото"
                  : "Просмотр фото"
              }
              setOpenWindow={() => setGalleryState("closed")}
              optionalCloseFunc={closeWindow}
              saveBtnOnClickFunc={handleSaveData}
              isNotHaveButtons
              ignorOnClickOutside
              isScroll
              containerClassName={styles.container}
            >
              <div className={styles.fullSize}>
                <div className={styles.grayBackgroundContainer}>
                  {galleryState === "uploading" && (
                    <PhotoAltGalleryUploadSection
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      open={open}
                    />
                  )}
                  {galleryState === "editing" && (
                    <PhotoAltGalleryEditSection
                      handleCloseEditing={handleCloseEditing}
                      handleSaveData={handleSaveData}
                      editingFromViewing={editingFromViewing}
                      imageIndex={imageIndex}
                      loadedImages={loadedImages}
                      files={files}
                      cropperRef={cropperRef}
                      closeWindow={closeWindow}
                    />
                  )}
                  {galleryState === "viewing" && (
                    <PhotoAltGalleryViewSection
                      imageIndex={imageIndex}
                      setImageIndex={setImageIndex}
                      loadedImages={loadedImages}
                      galleryState={galleryState}
                      cropperRef={cropperRef}
                    />
                  )}
                  {galleryState === "editing" && (
                    <div className={styles.imageNameBox}>
                      <span className={styles.imageName}>
                        Название изображения:
                      </span>{" "}
                      {imageName}
                    </div>
                  )}
                </div>
                {galleryState === "viewing" && (
                  <PhotoAltGalleryManageBox
                    imageName={imageName}
                    imageIndex={imageIndex}
                    makeMainPhoto={() => {
                      staffOneEditStore.makeAltPhotoTheMain(
                        loadedImages[imageIndex]?.hash
                      );
                    }}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
                {galleryState === "viewing" && (
                  <PhotoAltGalleryThumbnails
                    loadedImages={loadedImages}
                    imageIndex={imageIndex}
                    setImageIndex={setImageIndex}
                  />
                )}
              </div>
            </FormWindow>
          );
        }}
      </Formik>
      {/* Модальное окно для подтверждения удаления фото и для несохраненных изменений. */}
      {showModal && (
        <Modal
          type={
            modalType === "confirmDelete"
              ? "clarification"
              : "clarificationForFormWindows"
          }
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setModalType(null);
          }}
          title={
            modalType === "confirmDelete"
              ? "Вы уверены, что хотите удалить фото?"
              : "Внесены изменения."
          }
          subtitle={
            modalType === "unsavedChanges"
              ? "Вы уверены, что хотите закрыть окно без сохранения данных?"
              : undefined
          }
          btnWithCrossTitle={
            modalType === "confirmDelete" ? "Удалить" : "Закрыть без сохранения"
          }
          btnWithCrossOnClick={
            modalType === "confirmDelete"
              ? () => {
                  if (photoToDelete) {
                    staffOneEditStore.setDeletePhoto(photoToDelete);
                  }
                  setShowModal(false);
                  setModalType(null);
                }
              : () => {
                  setGalleryState("closed");
                  setShowModal(false);
                  setModalType(null);
                }
          }
          blueBtnTitle={
            modalType === "confirmDelete" ? "Отмена" : "Сохранить и закрыть"
          }
          blueBtnOnClick={
            modalType === "confirmDelete"
              ? () => {
                  setShowModal(false);
                  setModalType(null);
                }
              : () => {
                  handleSaveData();
                }
          }
          greyBtnTitle={modalType === "unsavedChanges" ? "Отмена" : undefined}
          greyBtnOnClick={
            modalType === "unsavedChanges"
              ? () => {
                  setShowModal(false);
                  setModalType(null);
                }
              : undefined
          }
          btnWithCrossImg
        />
      )}
    </>
  );
};

export default observer(StaffOneEditPhotoAltGallery);
