import styles from "./formWindow.module.scss";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import { useFormikContext } from "formik";

import FormWindowHeader from "./FormWindowHeader/index";
import Modal from "shared/ui/Modal";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";

import Alert from "shared/ui/Alert";
import Scrollbars from "react-custom-scrollbars-2";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import LoadedComponent from "widgets/LoadedComponent";
import { maxHeight } from "shared/utils/constants/maxHeight";
import { FormWindowProps } from "./types";
import { classNames } from "shared/utils/helpers/classNames";
import FormWindowHistory from "./FormWindowHistory";
import { getValues } from "shared/utils/helpers/getValues";

type ScrollValues = {
  scrollTop: number;
};

const FormWindow = ({
  title,
  children,
  setOpenWindow,

  subtitle,
  isScroll,

  optionalCloseFunc,
  buttonsOrder,
  saveBtnTitle,
  saveBtnOnClickFunc,
  saveBtnDisabledValue,

  saveSuccessModal,
  onSuccessModalClose,
  successMessageTitle,

  addBtnTitle,
  addBtnOnClickFunc,
  addBtnImg,
  addBtnDisabledValue,

  cancelBtnTitle,
  cancelBtnOnClick,
  cancelBtnDisabled,
  cancelBtnImg,

  deleteBtnTitle,
  deleteBtnOnClick,
  deleteBtnDisabled,
  deleteBtnImg,

  errors,
  isLoadingForModal,
  isNotHaveButtons,
  ignorOnClickOutside,
  fromOverlayWindow,
  dontCloseOnSave = false,

  withHistory,
  historyTitle,
  historyContent,

  unsavedChangesModalSubtitle,
  unsavedChangesModalTitle,
  deleteConfirmationModalTitle,

  hasBackBtn,
  withoutSendData,
  containerClassName
}: FormWindowProps) => {
  const { dirty, isValid, submitForm, handleReset } =
    !withoutSendData && useFormikContext();
  const ref = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<
    "unsavedChanges" | "deleteConfirmation" | null
  >(null);
  const [isCloseByBackBtn, setIsCloseByBackBtn] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [actionType, setActionType] = useState<"save" | "delete" | null>(null);

  const showSuccess = () => {
    const message =
      successMessageTitle?.[actionType] || "Операция выполнена успешно";
    setSuccessMessage(message);
    setShowSuccessModal(true);
    if (!dontCloseOnSave) {
      setIsVisible(false);
    }
    setActionType(null);
    onSuccessModalClose && onSuccessModalClose();
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (showSuccessModal) {
      if (dontCloseOnSave) {
        timeoutId = setTimeout(() => {
          setShowSuccessModal(false);
        }, 3000);
      } else {
        timeoutId = setTimeout(() => {
          setShowSuccessModal(false);
          setOpenWindow && setOpenWindow(false);
          optionalCloseFunc && optionalCloseFunc();
        }, 3000);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showSuccessModal, dontCloseOnSave]);

  const setCloseWindow = () => {
    optionalCloseFunc && optionalCloseFunc();
    setOpenWindow && setOpenWindow(false);
    onSuccessModalClose && onSuccessModalClose();
    setActionType(null);
  };

  const closeWindow = () => {
    if (!withoutSendData) {
      if (dirty) {
        setModalType("unsavedChanges");
        setOpenModal(true);
      } else {
        setCloseWindow();
        handleReset();
      }
    } else {
      setCloseWindow();
    }
  };

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (isLoadingForModal || ignorOnClickOutside || showSuccessModal) return;

      // условие ниже не позволяет закрывать окно при клике на выпадающий список
      if (
        event.target?.["dataset"]["list"] === "true" ||
        event.target?.["dataset"]["type"] === "list-search" ||
        (typeof event.target?.["childNodes"]?.[0] === "string" &&
          event.target?.["childNodes"]?.[0]?.includes("itemsScrollBoard")) ||
        event.target?.["parentNode"]?.["dataset"]["list"] === "true"
      )
        return;

      const className = event.target?.["firstChild"]?.["className"];

      if (className?.includes?.("window") || fromOverlayWindow) {
        closeWindow();
      }
    }
  });

  const onKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Escape" && !isLoadingForModal) {
      closeWindow();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalType(null);
  };

  const handleCrossClick = async () => {
    if (modalType === "unsavedChanges") {
      handleReset();
      setCloseWindow();
      if (isCloseByBackBtn && addBtnOnClickFunc) {
        await addBtnOnClickFunc();
        setIsCloseByBackBtn(false);
      }
    } else if (modalType === "deleteConfirmation") {
      setOpenModal(false);
      if (deleteBtnOnClick) {
        await deleteBtnOnClick();
      }
      setModalType(null);
    }
  };

  const handleSaveData = async () => {
    setActionType("save");
    if (saveBtnOnClickFunc) {
      await saveBtnOnClickFunc();
    } else {
      await submitForm();
    }
  };

  const handleAddButtonOnClick = () => {
    if (dirty) {
      setModalType("unsavedChanges");
      setOpenModal(true);
      hasBackBtn && setIsCloseByBackBtn(true);
    } else {
      !withoutSendData && handleReset();

      if (addBtnOnClickFunc && hasBackBtn) return addBtnOnClickFunc();

      setCloseWindow();
    }
  };

  const handleCancelClick = () => {
    if (dirty) {
      setModalType("unsavedChanges");
      setOpenModal(true);
    } else if (cancelBtnOnClick) {
      cancelBtnOnClick();
    }
  };

  const handleDeleteClick = () => {
    setActionType("delete");
    setModalType("deleteConfirmation");
    setOpenModal(true);
  };

  useEffect(() => {
    if (saveSuccessModal) {
      showSuccess();
    }
  }, [saveSuccessModal]);

  const handleScroll = (scrollValues: ScrollValues) => {
    if (isScroll) {
      setIsScrolled(scrollValues.scrollTop > 0);
    }
  };

  const renderErrors = () => {
    if (errors && getValues(errors).length) {
      return (
        <div className={styles.errors}>
          {Object.values(errors).map((error) => {
            return <Alert key={error.head} errors={error} />;
          })}
        </div>
      );
    }
  };

  const renderContent = () => {
    return (
      <div className={styles.paddingBox}>
        {children}
        {renderErrors()}
        {withHistory && historyContent && (
          <FormWindowHistory title={historyTitle}>
            {historyContent}
          </FormWindowHistory>
        )}
      </div>
    );
  };

  return (
    <>
      {isVisible && (
        <div className={styles.background}>
          <div className={styles.window} ref={ref}>
            <FormWindowHeader
              title={title}
              subtitle={subtitle}
              closeWindowFunc={
                !ignorOnClickOutside ? closeWindow : optionalCloseFunc
              }
              isScrolled={isScroll ? isScrolled : false}
            />
            <div
              className={classNames("", {
                [containerClassName]: containerClassName
              })}
            >
              {isScroll ? (
                <Scrollbars
                  width="100%"
                  autoHeight
                  autoHeightMax={`calc(${maxHeight} - 220px)`}
                  autoHide
                  className={styles.body}
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                  onScrollFrame={handleScroll}
                >
                  {renderContent()}
                </Scrollbars>
              ) : (
                <div className={styles.body}>{renderContent()}</div>
              )}
            </div>
            {isNotHaveButtons ? null : (
              <div
                className={classNames(styles.buttons, {
                  [styles.scrolled]: isScrolled
                })}
              >
                <ButtonsGroupForSettings
                  saveBtnTitle={saveBtnTitle}
                  saveBtnDisabled={saveBtnDisabledValue}
                  saveBtnOnClick={handleSaveData}
                  addBtnTitle={addBtnTitle}
                  addBtnOnClick={handleAddButtonOnClick}
                  addBtnDisabled={addBtnDisabledValue}
                  addBtnImg={addBtnImg}
                  cancelBtnTitle={cancelBtnTitle}
                  cancelBtnDisabled={cancelBtnDisabled}
                  cancelBtnOnClick={handleCancelClick}
                  cancelBtnImg={cancelBtnImg}
                  deleteBtnTitle={deleteBtnTitle}
                  deleteBtnOnClick={handleDeleteClick}
                  deleteBtnDisabled={deleteBtnDisabled}
                  deleteBtnImg={deleteBtnImg}
                  buttonsOrder={buttonsOrder}
                />
              </div>
            )}
            <LoadedComponent isLoading={isLoadingForModal} />
            {openModal && (
              <Modal
                type={
                  modalType === "unsavedChanges"
                    ? "clarificationForFormWindows"
                    : "clarification"
                }
                show={openModal}
                onHide={handleCloseModal}
                title={
                  modalType === "unsavedChanges"
                    ? unsavedChangesModalTitle || "Внесены изменения."
                    : deleteConfirmationModalTitle ||
                      "Вы уверены, что хотите удалить?"
                }
                subtitle={
                  modalType === "unsavedChanges"
                    ? unsavedChangesModalSubtitle ||
                      "Вы уверены, что хотите закрыть окно без сохранения данных?"
                    : ""
                }
                btnWithCrossTitle={
                  modalType === "unsavedChanges"
                    ? "Закрыть без сохранения"
                    : "Удалить"
                }
                btnWithCrossOnClick={handleCrossClick}
                blueBtnTitle={
                  modalType === "unsavedChanges" ? "Сохранить и закрыть" : ""
                }
                blueBtnOnClick={
                  modalType === "unsavedChanges" ? handleSaveData : undefined
                }
                blueBtnDisabled={!isValid}
                greyBtnOnClick={handleCloseModal}
                greyBtnTitle="Отмена"
                btnWithCrossImg
              />
            )}
          </div>
        </div>
      )}
      {showSuccessModal && (
        <Modal
          type="success"
          show={showSuccessModal}
          onHide={() => {
            setShowSuccessModal(false);
            if (!dontCloseOnSave) {
              setOpenWindow && setOpenWindow(false);
              optionalCloseFunc && optionalCloseFunc();
            }
          }}
          title={successMessage}
          direction="row"
          successIcon
        />
      )}
    </>
  );
};

export default observer(FormWindow);
