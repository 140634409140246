import { observer } from "mobx-react-lite";
import styles from "./staffRetention.module.scss";
import { useEffect, useState } from "react";
import TableWithStickyFilter from "features/TableWithStickyFilter";
import { useStores } from "stores";
import StaffRetentionTable from "./StaffRetentionTable";
import StaffSalaryForm from "features/StaffSalaryForm";

import { browserName } from "react-device-detect";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { Button, ButtonTheme } from "shared/ui/Button";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import { ReactComponent as IconAddDisabled } from "shared/assets/images/mainIcons/iconAdd/iconAddDisabledWithoutBackground.svg";

const StaffRetention = () => {
  const { menuStore, filterStore, staffRetentionListStore } = useStores();

  const [isOpenModalWindow, setIsOpenModalWindow] = useState(false);

  useEffect(() => {
    if (!staffRetentionListStore.holdingEduList.length) {
      staffRetentionListStore.getData();
    }
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/staffRetention",
      title: "Сотрудники"
    });
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("staffRetention");
  }, []);

  useEffect(() => {
    if (
      staffRetentionListStore.page !== 1 &&
      staffRetentionListStore.page <= staffRetentionListStore.maxPage &&
      staffRetentionListStore.page !== staffRetentionListStore.prevPage
    ) {
      staffRetentionListStore.getMoreWithHoldingEduList();
    }
  }, [staffRetentionListStore.page, staffRetentionListStore.maxPage]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableWithStickyFilter
        isLoading={staffRetentionListStore.isLoading}
        isLoadingForFilters={staffRetentionListStore.isLoadingForFilters}
        searchValue={staffRetentionListStore.searchValue}
        setSearchValue={staffRetentionListStore.setSearchValue}
        getData={staffRetentionListStore.getWithHoldingEduList}
        selectedFilter={staffRetentionListStore.selectedFilter}
        setSelectedFilter={staffRetentionListStore.setSelectedFilter}
        cols={staffRetentionListStore.staffTableCols}
        params={staffRetentionListStore.staffTableParams}
        allCols={staffRetentionListStore.staffColsAll}
        currentCols={staffRetentionListStore.currentTitles}
        foundCounter={staffRetentionListStore.foundCounter}
        isSearchable
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(
            !staffRetentionListStore.filters[
              staffRetentionListStore.downloadedFilter
            ]?.["general"] || staffRetentionListStore.searchValue
          ),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        withoutAdvancedSearch
        withoutSavedFilter
        withoutColsSetting
        withoutQueryParams
        fixedWidthSearchInput
        moreButton={
          <Button
            className={styles.addBtn}
            type="button"
            theme={ButtonTheme.CLEAR}
            onClick={() => {
              setIsOpenModalWindow(true);
            }}
            disabled={isOpenModalWindow}
            id="StaffOneSalaryRetentionUC_addCompensation"
          >
            Добавить вычет
            {isOpenModalWindow ? <IconAddDisabled /> : <IconAdd />}
          </Button>
        }
      >
        <StaffRetentionTable />
      </TableWithStickyFilter>
      <StaffSalaryForm
        title="Добавление вычета УЦ"
        isOpenModal={isOpenModalWindow}
        setIsOpenModal={() => {
          setIsOpenModalWindow(false);
        }}
        clearErrorsMessage={() => staffRetentionListStore.clearErrorsMessage()}
        type="add"
        fields={["uid", "date_start", "money", "ws_num", "comment"]}
        disabledFields={["money", "ws_num"]}
        saveBtnOnClickFunc={staffRetentionListStore.addHoldingEdu}
        errorsMessage={staffRetentionListStore.errorsMessage}
        money={staffRetentionListStore.money}
        ws_num={staffRetentionListStore.ws_num}
        isHoldingEdu
        saveSuccessModal={staffRetentionListStore.success}
        onSuccessModalClose={() => {
          staffRetentionListStore.setSuccess(false);
        }}
        isLoadingForModal={staffRetentionListStore.isLoadingForForm}
      />
    </ErrorBoundary>
  );
};

export default observer(StaffRetention);
