import styles from "./itemScrollBoardItem.module.scss";
import { observer } from "mobx-react-lite";
import StatusIcon from "shared/ui/StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseForBtn.svg";

type ItemsScrollBoardItemProps = {
  isItemBtnMode?: boolean;
  isSearchWithPagination?: boolean;
  itemId: string | boolean;
  itemTitle: string;
  onClick: () => void;
  hasGrayBorder?: boolean;
  hasBlueBorder?: boolean;
  isPressed?: boolean;
};

const ItemsScrollBoardItem = ({
  isItemBtnMode,
  isSearchWithPagination,
  itemId,
  itemTitle,
  onClick,
  hasGrayBorder,
  hasBlueBorder,
  isPressed
}: ItemsScrollBoardItemProps) => {
  const getClassNames = () => {
    if (isSearchWithPagination) {
      return classNames("", {
        [styles.itemBlock]: isItemBtnMode,
        [styles.grayBorder]: !isItemBtnMode
      });
    } else {
      return classNames("", {
        [styles.itemBlock]: isItemBtnMode,
        [styles.grayBorder]: !isItemBtnMode && hasGrayBorder,
        [styles.blueBorder]: !isItemBtnMode && hasBlueBorder,
        [styles.pressed]: !isItemBtnMode && isPressed
      });
    }
  };
  return (
    <div
      onClick={onClick}
      id={`ItemsScrollBoardItem_item_${itemId}`}
      className={getClassNames()}
      data-list="true"
    >
      <div
        className={classNames(styles.item, {
          [styles.minHeight]: !isItemBtnMode,
          [styles.itemZero]: isItemBtnMode,
          [styles.pressed]: !isItemBtnMode && isPressed
        })}
        data-list="true"
      >
        {itemTitle}
      </div>
      {isItemBtnMode && (
        <StatusIcon
          icon="iconaddwithoutcircle"
          color="bw-gray5"
          data-list="true"
        />
      )}
      {isPressed && (
        <div className={styles.closeIconWrapper} data-list="true">
          <IconClose className={styles.closeIcon} data-list="true" />
        </div>
      )}
    </div>
  );
};

export default observer(ItemsScrollBoardItem);
