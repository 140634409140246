import styles from "./awardsGalleryWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Formik } from "formik";
import validationDate from "./validation";
import WindowBody from "./WindowBody";
import History from "./History";
import { useStores } from "stores";
import FormWindow from "shared/ui/FormWindow";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Award } from "stores/StaffModule/types/Award";
import { isEmpty } from "lodash";
import WindowTabs from "./WindowTabs";

type AwardsGalleryWindowProps = {
  openWindow: boolean;
  setOpenWindow: (openedWindow: boolean) => void;
};

const AwardsGalleryWindow = ({
  openWindow,
  setOpenWindow
}: AwardsGalleryWindowProps) => {
  const { staffOneAwardsStore } = useStores();
  const [changeAward, setChangeAward] = useState(false);
  const [withoutAwards, setWithoutAwards] = useState(false);

  const currentAward: Award =
    staffOneAwardsStore.awards[staffOneAwardsStore.openedAward];

  const initialValues = {
    staff_id: staffOneAwardsStore.staffInfo.id,
    award_id: currentAward?.id,
    award_type: currentAward?.type,
    award_start: currentAward?.award_start
  };

  const handleSave = async (values) => {
    await staffOneAwardsStore.updateAward(
      values.staff_id,
      values.award_id,
      values.award_start
    );
  };

  const handleDelete = async () => {
    await staffOneAwardsStore.deleteAward(
      initialValues.staff_id,
      currentAward.id,
      currentAward?.type_title
    );
  };

  return (
    <>
      {openWindow && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationDate}
          onSubmit={handleSave}
          enableReinitialize
        >
          {({ dirty, handleReset, isValid, submitForm }) => {
            const handleCancel = () => {
              if (changeAward) {
                handleReset();
                setChangeAward(false);
              }
            };

            return (
              <FormWindow
                title="Награда"
                subtitle={`${staffOneAwardsStore.staffInfo.surname} ${
                  staffOneAwardsStore.staffInfo.name
                } ${staffOneAwardsStore.staffInfo.patronymic || ""}`}
                setOpenWindow={setOpenWindow}
                optionalCloseFunc={handleCancel}
                isLoadingForModal={
                  staffOneAwardsStore.isLoadingForModal ||
                  staffOneAwardsStore.isLoading
                }
                saveBtnTitle={
                  changeAward ? "Сохранить изменения" : "Редактировать"
                }
                saveBtnOnClickFunc={
                  changeAward ? submitForm : () => setChangeAward(true)
                }
                saveBtnDisabledValue={
                  (changeAward && !dirty) ||
                  !isValid ||
                  !isEmpty(staffOneAwardsStore.errorsMessage)
                }
                cancelBtnTitle={changeAward ? "Отмена" : undefined}
                cancelBtnOnClick={handleCancel}
                deleteBtnTitle={!changeAward ? "Удалить награду" : undefined}
                deleteBtnOnClick={!changeAward ? handleDelete : undefined}
                deleteConfirmationModalTitle={`Вы уверены, что хотите удалить награду "${currentAward?.type_title}"?`}
                unsavedChangesModalSubtitle={
                  "Вы уверены, что хотите закрыть окно без сохранения данных?"
                }
                saveSuccessModal={staffOneAwardsStore.success}
                successMessageTitle={{
                  save: "Изменения сохранены",
                  delete: "Награда удалена"
                }}
                onSuccessModalClose={() => {
                  staffOneAwardsStore.setSuccess(false);
                }}
                isNotHaveButtons={withoutAwards}
                withHistory={!withoutAwards}
                historyTitle="История изменений"
                historyContent={
                  <History history={currentAward?.history} isCollapsible />
                }
                isScroll
              >
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {changeAward ? (
                    <div className={styles.awardDetails}>
                      <p className={styles.title}>Группа</p>
                      <div className={styles.groupName}>
                        {
                          staffOneAwardsStore.awardGroups[
                            currentAward?.custom?.group
                          ]?.title
                        }
                      </div>
                    </div>
                  ) : (
                    <div className={styles.group}>
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <WindowTabs
                          setOpenedAward={staffOneAwardsStore.setOpenedAward}
                          setWithoutAwards={setWithoutAwards}
                        />
                      </ErrorBoundary>
                    </div>
                  )}
                  <WindowBody
                    currentAward={currentAward}
                    changeAward={changeAward}
                    withoutAwards={withoutAwards}
                  />
                </ErrorBoundary>
              </FormWindow>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default observer(AwardsGalleryWindow);
