import styles from "./buildingOneStaffTableOV.module.scss";
import { observer } from "mobx-react-lite";

import { useParams } from "react-router-dom";
import { useStores } from "stores";
import { Formik } from "formik";

import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { InputNumber } from "shared/ui/Inputs/InputNumber";
import FormWindow from "shared/ui/FormWindow";

import { ReactComponent as IconEdit } from "shared/assets/images/mainIcons/iconEdit/iconEditWithoutFill.svg";
import icon1C from "shared/assets/images/mainIcons/icon1C.svg";

import validation from "./validation";

import { Staff } from "stores/BuildingModule/types/BuildingStaffType";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";
import Alert from "shared/ui/Alert";

type BuildingOneStaffTableOVProps = {
  staff_data: Staff;
  col_name: string;
  col_title: string;
  staff_id: string;
  openedListName: string;
  changeOpenedListName: (arg: string) => void;
  isOpenedWindowCalendar: boolean;
  setIsOpenedWindowCalendar: (arg: boolean) => void;
};

const BuildingOneStaffTableOV = ({
  staff_data,
  col_name,
  col_title,
  staff_id,
  openedListName,
  changeOpenedListName,
  isOpenedWindowCalendar,
  setIsOpenedWindowCalendar
}: BuildingOneStaffTableOVProps) => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  const handleClickIconEdit = () => {
    changeOpenedListName(`${staff_id}.${col_name}`);
    switch (col_name) {
      case "over_price":
        buildingOneStaffStore.getOverPriceHistory(staff_data.building_staff_id);
        break;
      default:
        buildingOneStaffStore.getOVRecords(col_name, staff_data.staff_id);
    }
  };

  return (
    <>
      {(() => {
        if (buildingOneStaffStore.openedType !== "edit") {
          switch (col_name) {
            case "invest":
              return (
                <div>
                  <p>
                    {!staff_data[col_name]
                      ? "100 / 0"
                      : `${100 - staff_data[col_name].value} / ${
                          staff_data[col_name].value
                        }`}
                  </p>
                </div>
              );
            case "over_price":
              return (
                <div>
                  <p>
                    {staff_data[col_name] ? staff_data[col_name].value : ""}
                  </p>
                </div>
              );
          }
        }
      })()}
      {buildingOneStaffStore.openedType === "edit" ? (
        <>
          <div
            className={classNames(styles.input, {
              [styles.input_active]:
                openedListName === `${staff_id}.${col_name}`
            })}
          >
            {col_name === "invest"
              ? !staff_data[col_name]
                ? "0 / 100"
                : `${staff_data[col_name].value} / ${
                    100 - staff_data[col_name].value
                  }`
              : staff_data[col_name]
              ? staff_data[col_name].value
              : "0"}
            <IconEdit
              className={classNames(styles.input__icon, {
                [styles.input__icon_active]:
                  openedListName === `${staff_id}.${col_name}`
              })}
              id={`BuildingOneStaffTableOV_editBtn_${staff_id}`}
              onClick={handleClickIconEdit}
            />
          </div>
          {openedListName === `${staff_id}.${col_name}` ? (
            <Formik
              initialValues={{
                start_date: null,
                start_time: "12:00",
                value: "",
                history:
                  buildingOneStaffStore.versionHistory[id]?.[col_name] &&
                  Object.values(
                    buildingOneStaffStore.versionHistory[id][col_name]
                  ).sort((a, b) => {
                    if (
                      new Date(
                        `${b["start_date"]} ${
                          b["start_time"] ? b["start_time"] : ""
                        }`
                      ).valueOf() !==
                      new Date(
                        `${a["start_date"]} ${
                          a["start_time"] ? a["start_time"] : ""
                        }`
                      ).valueOf()
                    ) {
                      return (
                        new Date(
                          `${b["start_date"]} ${
                            b["start_time"] ? b["start_time"] : ""
                          }`
                        ).valueOf() -
                        new Date(
                          `${a["start_date"]} ${
                            a["start_time"] ? a["start_time"] : ""
                          }`
                        ).valueOf()
                      );
                    }
                    return (
                      new Date(b["time_create"]).valueOf() -
                      new Date(a["time_create"]).valueOf()
                    );
                  })
              }}
              enableReinitialize
              validationSchema={validation}
              onSubmit={() => {
                return;
              }}
            >
              {({ dirty, values, isValid }) => {
                const handleSaveBtnOnClick = async () => {
                  const OVData = Object.values(values.history)[0];

                  if (values["value"] !== null && values["start_date"]) {
                    if (col_name !== "over_price") {
                      await buildingOneStaffStore.addOVData(
                        col_name,
                        OVData?.["rid"] || staff_data.staff_id,
                        values.start_date,
                        values.value,
                        OVData?.["staff_code"] || 0
                      );
                    } else {
                      await buildingOneStaffStore.addOverPriceData(
                        OVData?.["rid"] || staff_data.building_staff_id,
                        values.start_date,
                        values.value
                      );
                    }
                  }
                };

                return (
                  <FormWindow
                    title={col_title}
                    subtitle={staff_data.name}
                    setOpenWindow={() => {
                      changeOpenedListName("");
                    }}
                    saveBtnTitle="Сохранить"
                    saveBtnOnClickFunc={handleSaveBtnOnClick}
                    saveBtnDisabledValue={!dirty || !isValid}
                    saveSuccessModal={buildingOneStaffStore.success}
                    onSuccessModalClose={() =>
                      buildingOneStaffStore.setSuccess(false)
                    }
                    successMessageTitle={{
                      save: "Успешно сохранено"
                    }}
                    addBtnTitle="Отмена"
                    isScroll
                    fromOverlayWindow
                    isLoadingForModal={buildingOneStaffStore.isLoadingForForm}
                    errors={
                      buildingOneStaffStore.errorsMessage[id]
                        ? Object.values(buildingOneStaffStore.errorsMessage[id])
                        : []
                    }
                    containerClassName={styles.container}
                  >
                    <div className={styles.inputs}>
                      <InputNumber
                        name="value"
                        label="Значение"
                        value={values.value}
                        withoutBtns
                      />
                      <DatePickerField
                        title="Дата начала"
                        name="start_date"
                        isCalendarOpened={isOpenedWindowCalendar}
                        setIsCalendarOpened={setIsOpenedWindowCalendar}
                        placement="bottom-end"
                      />

                      <div className={styles.history__title}>История</div>
                      {values.history?.length ? (
                        <>
                          {Object.values(values.history).map((item, i) => {
                            return (
                              <div
                                key={item["id"]}
                                className={styles.storyData}
                              >
                                <div className={styles.storyData__item}>
                                  <div
                                    className={classNames(
                                      styles.storyData__date,
                                      {},
                                      [styles.storyData__date_withMargin]
                                    )}
                                  >
                                    Дата и время начала:
                                  </div>
                                  <div className={styles.storyData__date}>
                                    {getFormattedDate(item["start_date"])}
                                  </div>
                                  <div className={styles.storyData__date}>
                                    {item["start_time"]}
                                  </div>
                                </div>
                                <div
                                  className={classNames(
                                    styles.storyData__item,
                                    {},
                                    [styles.storyData__item_withMargin]
                                  )}
                                >
                                  <div
                                    className={classNames(
                                      styles.storyData__date,
                                      {},
                                      [styles.storyData__date_withMargin]
                                    )}
                                  >
                                    Создано:
                                  </div>
                                  <div className={styles.storyData__date}>
                                    {getFormattedDate(item["time_create"])}
                                  </div>
                                  <div className={styles.storyData__name}>
                                    {item["user_name"] === "crm_bot" ? (
                                      <img src={icon1C} alt="icon" />
                                    ) : (
                                      item["user_name"]
                                    )}
                                  </div>
                                </div>
                                <div className={styles.storyData__inputBlock}>
                                  <div className={styles.storyData__input}>
                                    <InputNumber
                                      name={`history.${i}.value`}
                                      label="Значение"
                                      value={item["value"]}
                                      withoutBtns
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <Alert
                          errors={{ head: "Нет данных", color: "empty" }}
                        />
                      )}
                    </div>
                  </FormWindow>
                );
              }}
            </Formik>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default observer(BuildingOneStaffTableOV);
