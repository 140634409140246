import styles from "./staffListTable.module.scss";
import { useEffect, useRef, useState } from "react";
import { useStores } from "stores/index";
import { observer } from "mobx-react-lite";

import LoadedComponent from "widgets/LoadedComponent";
import Loading from "widgets/LoadedComponent/Loading";
import { Table } from "react-bootstrap";
import ScrollButton from "shared/ui/Buttons/ScrollButton";
import { useNavigate } from "react-router-dom";
import StatusIcon from "shared/ui/StatusIcon";

import { browserName } from "react-device-detect";
import { fileUrl } from "stores/utils/consts";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";
import { Badge } from "shared/ui/Badge";
import LoaderSpinner from "shared/ui/LoaderSpinner";
import { isObject } from "lodash";
import { getKeys } from "shared/utils/helpers/getKeys";
import Alert from "shared/ui/Alert";
import { getValues } from "shared/utils/helpers/getValues";
import { classNames } from "shared/utils/helpers/classNames";
import { calculateYears } from "shared/utils/helpers/calculateYears";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";

type StaffListTableProps = {
  handleClick: () => void;
  filterItems: (arg: string) => void;
};

const StaffListTable = observer(({ filterItems }: StaffListTableProps) => {
  const { staffListStore, menuStore, filterStore } = useStores();
  const [isClickTimeout, setIsClickTimeout] = useState(true);
  const navigate = useNavigate();
  const navigateUserProfile = (id: string) => {
    navigate(`/staff/id=${id}`);
    menuStore.setScrollPosition(menuStore.scroll);
  };
  const tableRef = useRef<HTMLTableElement>();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      staffListStore.maxPage >= staffListStore.page &&
      staffListStore.page === staffListStore.prevPage
    ) {
      staffListStore.setPage(staffListStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  const getTitle = (key: string) => {
    if (
      getKeys(staffListStore.staffColsAll).length &&
      staffListStore.staffColsAll[key]
    ) {
      return staffListStore.staffColsAll[key]["title"];
    } else return key;
  };

  return (
    <>
      {getValues(staffListStore.staffColsAll).length &&
      staffListStore.currentTitles.length ? (
        <>
          <ScrollButton tableRef={tableRef} />
          <Table
            className={styles.table}
            style={{
              marginTop: getTableMarginTop(
                filterStore.savedFiltersHeight,
                Boolean(
                  !staffListStore.filters[staffListStore.downloadedFilter]?.[
                    "general"
                  ] || staffListStore.searchValue
                ),
                filterStore.isOpenAdvancedSearch ||
                  filterStore.isOpenedSavedFilterOptions ||
                  filterStore.isOpenedColumnsOptions,
                browserName === "Firefox"
              ),
              transition: "0.2s"
            }}
            ref={tableRef}
          >
            <thead
              style={{
                top: getTHeadTop(
                  filterStore.savedFiltersHeight,
                  Boolean(
                    !staffListStore.filters[staffListStore.downloadedFilter]?.[
                      "general"
                    ] || staffListStore.searchValue
                  ),
                  filterStore.isOpenedSavedFilters && browserName === "Firefox"
                )
              }}
            >
              <tr className={styles.row_white}>
                {staffListStore.currentTitles.map((title) => {
                  const isSortedIcon =
                    !staffListStore.notShowAsColumnList.includes(title) &&
                    staffListStore.staffTableParams[title]?.useAsFilter ===
                      "on";

                  return (
                    <th key={`th_${title}`} className={styles.title}>
                      {isSortedIcon ? (
                        <div
                          className={classNames(styles.sortedIcon, {}, [
                            styles.pointer
                          ])}
                          onClick={() => filterItems(title)}
                          id={`list_filter_${title}`}
                        >
                          <p className={styles.titleText}>{getTitle(title)}</p>
                          <StatusIcon
                            icon="iconarrowdouble"
                            color={
                              title === staffListStore.staffListOrder
                                ? "blue-lazure"
                                : "bw-gray5"
                            }
                          />
                        </div>
                      ) : (
                        <div className={styles.nonSorted}>
                          <p className={styles.nonSortedText}>
                            {getTitle(title)}
                          </p>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {staffListStore.staffList.length ? (
              staffListStore.staffList.map((oneOfStaff, index) => {
                const isGray = oneOfStaff.grey_list === 1;
                const isBlack = oneOfStaff.black_list === 1;
                const companyCount = isObject(oneOfStaff.company)
                  ? getKeys(oneOfStaff.company).length
                  : 1;

                const onMouseDown = () => {
                  setIsClickTimeout(false);
                  setTimeout(() => {
                    setIsClickTimeout(true);
                  }, 500);
                };

                const onMouseUp = (event: { button: number }) => {
                  !isClickTimeout &&
                    event.button !== 2 &&
                    navigateUserProfile(oneOfStaff.id);
                };

                if (typeof oneOfStaff.company !== "string") {
                  return (
                    <tbody key={oneOfStaff.id}>
                      {getKeys(oneOfStaff.company).map(
                        (companyId, companyIdx) => {
                          return (
                            <tr
                              key={oneOfStaff.company[companyId]}
                              className={classNames(styles["row_bw-gray1"], {
                                [styles["row_bw-white"]]: index % 2,
                                [styles["row_bw-gray5"]]: isBlack,
                                [styles["row_bw-gray4"]]: isGray
                              })}
                              onMouseDown={onMouseDown}
                              onMouseUp={onMouseUp}
                            >
                              {staffListStore.currentTitles.map((title) => {
                                const isIconOk =
                                  staffListStore.staffColsAll[title]["type"] ===
                                    "bool" &&
                                  typeof oneOfStaff[title] !== "object" &&
                                  oneOfStaff[title] === 1;
                                const isIconDash =
                                  staffListStore.staffColsAll[title]["type"] ===
                                  "bool";
                                typeof oneOfStaff[title] !== "object" &&
                                  oneOfStaff[title] === 0;
                                switch (title) {
                                  case "uid":
                                    return (
                                      companyIdx === 0 && (
                                        <td
                                          key={`${oneOfStaff.uid}_${title}`}
                                          rowSpan={companyCount}
                                        >
                                          <div
                                            className={classNames(
                                              styles.wrapper,
                                              {},
                                              [styles.tN]
                                            )}
                                            id={`StaffListTable_goToStaffPage_${title}`}
                                          >
                                            <p className={styles.value}>
                                              {oneOfStaff[title]}
                                            </p>
                                            {isGray || isBlack ? (
                                              <Badge
                                                text={
                                                  isBlack
                                                    ? "ЧС Компании"
                                                    : "СС Проекта"
                                                }
                                                colorName={
                                                  isBlack
                                                    ? "bw-gray7"
                                                    : "bw-gray6"
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </td>
                                      )
                                    );
                                  case "surname":
                                  case "name":
                                  case "patronymic":
                                  case "snils":
                                  case "propisk":
                                  case "pasp_n":
                                    return (
                                      companyIdx === 0 && (
                                        <td
                                          key={`${oneOfStaff.id}_${title}`}
                                          rowSpan={companyCount}
                                        >
                                          <div
                                            className={styles.wrapper}
                                            id={`StaffListTable_goToStaffPage_${title}`}
                                          >
                                            {oneOfStaff[title] && (
                                              <p className={styles.value}>
                                                {oneOfStaff[title]}
                                              </p>
                                            )}
                                          </div>
                                        </td>
                                      )
                                    );
                                  case "birthday":
                                    return (
                                      companyIdx === 0 && (
                                        <td
                                          key={`${oneOfStaff.id}_${title}`}
                                          rowSpan={companyCount}
                                        >
                                          <div
                                            className={styles.wrapper}
                                            id={`StaffListTable_goToStaffPage_${title}`}
                                          >
                                            {oneOfStaff[title] && (
                                              <>
                                                <p className={styles.value}>
                                                  {oneOfStaff[title]}
                                                </p>
                                                <div
                                                  className={styles.birthday}
                                                >
                                                  (
                                                  {calculateYears(
                                                    getParsedDate(
                                                      oneOfStaff[title]
                                                    ),
                                                    new Date()
                                                  )}
                                                  )
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </td>
                                      )
                                    );
                                  case "event_type":
                                    return (
                                      <td key={`${oneOfStaff.id}_${title}`}>
                                        {oneOfStaff[title] ? (
                                          <div className={styles.wrapper}>
                                            <Badge
                                              icon={
                                                oneOfStaff[title][companyId]
                                                  ?.icon
                                              }
                                              iconColor={
                                                oneOfStaff[title][companyId]
                                                  ?.color
                                              }
                                              text={
                                                oneOfStaff[title][companyId]
                                                  ?.title
                                              }
                                              textColorName="bw-black"
                                              colorName="none"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    );
                                  case "company":
                                  case "position":
                                    return (
                                      <td key={`${oneOfStaff.id}_${title}`}>
                                        {oneOfStaff[title] !== null &&
                                        typeof oneOfStaff[title] ===
                                          "object" ? (
                                          <div className={styles.wrapper}>
                                            <p>
                                              {oneOfStaff[title][companyId] ||
                                                ""}
                                            </p>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    );
                                  case "building":
                                    return (
                                      <td
                                        key={`${oneOfStaff.id}_${title}`}
                                        onMouseDown={onMouseDown}
                                        onMouseUp={(event) => {
                                          !isClickTimeout &&
                                            event.button !== 2 &&
                                            window.open(
                                              `${fileUrl}/crm/building/?id=${oneOfStaff[title][companyId]?.["id"]}`,
                                              "_blank",
                                              "noreferrer"
                                            );
                                        }}
                                      >
                                        {oneOfStaff[title] !== null &&
                                        typeof oneOfStaff[title] ===
                                          "object" ? (
                                          <div className={styles.wrapper}>
                                            <p
                                              className={styles.linkToBuilding}
                                            >
                                              {oneOfStaff[title][companyId]?.[
                                                "title"
                                              ] || ""}
                                            </p>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    );
                                  case "phone":
                                    return (
                                      <td
                                        key={`${oneOfStaff.id}_${title}`}
                                        className={styles.maxContent}
                                      >
                                        <div className={styles.wrapperBlock}>
                                          {getValues(oneOfStaff[title]).length
                                            ? getValues(oneOfStaff[title]).map(
                                                (item) => (
                                                  <div
                                                    key={`${oneOfStaff.id}_${title}_${item}`}
                                                    className={classNames(
                                                      styles.wrapperZero,
                                                      {},
                                                      [styles.maxContentWrapper]
                                                    )}
                                                  >
                                                    {item["confirm"] ? (
                                                      <StatusIcon
                                                        icon="bigcheck"
                                                        color="accent-green"
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    <p>
                                                      {formatPhoneNumber(
                                                        item["number"]
                                                      )}
                                                    </p>
                                                  </div>
                                                )
                                              )
                                            : ""}
                                        </div>
                                      </td>
                                    );
                                  case "count_work_all": {
                                    return (
                                      companyIdx === 0 && (
                                        <td
                                          key={`count_work_${oneOfStaff.id}`}
                                          className={styles.countWorkAll}
                                          rowSpan={companyCount}
                                        >
                                          <div className={styles.wrapper}>
                                            {staffListStore.loadingCountWorkAll[
                                              oneOfStaff.id
                                            ] ? (
                                              <LoaderSpinner
                                                color="bw-gray4"
                                                size="small"
                                              />
                                            ) : (
                                              oneOfStaff[title]
                                            )}
                                          </div>
                                        </td>
                                      )
                                    );
                                  }
                                  default:
                                    return (
                                      companyIdx === 0 && (
                                        <td
                                          key={`${oneOfStaff.id}_${title}`}
                                          rowSpan={companyCount}
                                        >
                                          {typeof oneOfStaff[title] !==
                                          "object" ? (
                                            <div className={styles.wrapper}>
                                              {isIconOk ? (
                                                <StatusIcon
                                                  icon="bigcheck"
                                                  color="accent-green"
                                                />
                                              ) : isIconDash ? (
                                                ""
                                              ) : (
                                                <p
                                                  style={
                                                    title === "comment"
                                                      ? {
                                                          wordBreak:
                                                            "break-word",
                                                          maxWidth: "200px"
                                                        }
                                                      : null
                                                  }
                                                >
                                                  {oneOfStaff[title]}
                                                </p>
                                              )}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      )
                                    );
                                }
                              })}
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  );
                } else {
                  return (
                    <tbody key={oneOfStaff.id}>
                      <tr
                        className={classNames(styles["row_bw-gray1"], {
                          [styles["row_bw-white"]]: index % 2,
                          [styles["row_bw-gray5"]]: isBlack,
                          [styles["row_bw-gray4"]]: isGray
                        })}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                      >
                        {staffListStore.currentTitles.map((title) => {
                          const isIconOk =
                            staffListStore.staffColsAll[title]["type"] ===
                              "bool" &&
                            typeof oneOfStaff[title] !== "object" &&
                            oneOfStaff[title] === 1;
                          const isIconDash =
                            staffListStore.staffColsAll[title]["type"] ===
                            "bool";
                          typeof oneOfStaff[title] !== "object" &&
                            oneOfStaff[title] === 0;

                          switch (title) {
                            case "uid":
                              return (
                                <td key={`${oneOfStaff.uid}_${title}`}>
                                  <div
                                    className={classNames(styles.wrapper, {}, [
                                      styles.tN
                                    ])}
                                    id={`StaffListTable_goToStaffPage_${title}`}
                                  >
                                    <p className={styles.value}>
                                      {oneOfStaff[title]}
                                    </p>
                                    {isGray || isBlack ? (
                                      <Badge
                                        text={
                                          isBlack ? "ЧС Компании" : "СС Проекта"
                                        }
                                        colorName={
                                          isBlack ? "bw-gray7" : "bw-gray6"
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              );
                            case "surname":
                            case "name":
                            case "patronymic":
                            case "snils":
                            case "propisk":
                            case "pasp_n":
                              return (
                                <td key={`${oneOfStaff.id}_${title}`}>
                                  <div
                                    className={styles.wrapper}
                                    id={`StaffListTable_goToStaffPage_${title}`}
                                  >
                                    {oneOfStaff[title] && (
                                      <p className={styles.value}>
                                        {oneOfStaff[title]}
                                      </p>
                                    )}
                                  </div>
                                </td>
                              );
                            case "birthday":
                              return (
                                <td key={`${oneOfStaff.id}_${title}`}>
                                  <div
                                    className={styles.wrapper}
                                    id={`StaffListTable_goToStaffPage_${title}`}
                                  >
                                    {oneOfStaff[title] && (
                                      <>
                                        <p className={styles.value}>
                                          {oneOfStaff[title]}
                                        </p>
                                        <div className={styles.birthday}>
                                          (
                                          {calculateYears(
                                            getParsedDate(oneOfStaff[title]),
                                            new Date()
                                          )}
                                          )
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </td>
                              );
                            case "event_type":
                            case "company":
                            case "position":
                            case "building":
                              return (
                                <td key={`${oneOfStaff.id}_${title}`}></td>
                              );

                            case "phone":
                              return (
                                <td
                                  key={`${oneOfStaff.id}_${title}`}
                                  className={styles.maxContent}
                                >
                                  <div className={styles.wrapperBlock}>
                                    {getValues(oneOfStaff[title]).length
                                      ? getValues(oneOfStaff[title]).map(
                                          (item) => (
                                            <div
                                              key={`${oneOfStaff.id}_${title}_${item}`}
                                              className={classNames(
                                                styles.wrapperZero,
                                                {},
                                                [styles.maxContentWrapper]
                                              )}
                                            >
                                              {item["confirm"] ? (
                                                <StatusIcon
                                                  icon="bigcheck"
                                                  color="accent-green"
                                                />
                                              ) : (
                                                ""
                                              )}
                                              <p>
                                                {formatPhoneNumber(
                                                  item["number"]
                                                )}
                                              </p>
                                            </div>
                                          )
                                        )
                                      : ""}
                                  </div>
                                </td>
                              );
                            case "count_work_all":
                              return (
                                <td
                                  key={`count_work_${oneOfStaff.id}`}
                                  className={styles.countWorkAll}
                                >
                                  <div className={styles.wrapper}>
                                    {staffListStore.loadingCountWorkAll[
                                      oneOfStaff.id
                                    ] ? (
                                      <LoaderSpinner
                                        color="bw-gray4"
                                        size="small"
                                      />
                                    ) : (
                                      oneOfStaff[title]
                                    )}
                                  </div>
                                </td>
                              );
                            default:
                              return (
                                <td key={`${oneOfStaff.id}_${title}`}>
                                  {typeof oneOfStaff[title] !== "object" ? (
                                    <div className={styles.wrapper}>
                                      {isIconOk ? (
                                        <StatusIcon
                                          icon="bigcheck"
                                          color="accent-green"
                                        />
                                      ) : isIconDash ? (
                                        ""
                                      ) : (
                                        <p
                                          style={
                                            title === "comment"
                                              ? {
                                                  wordBreak: "break-word",
                                                  maxWidth: "200px"
                                                }
                                              : null
                                          }
                                        >
                                          {oneOfStaff[title]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                          }
                        })}
                      </tr>
                    </tbody>
                  );
                }
              })
            ) : (
              <tbody>
                <tr className={styles.error}>
                  <td colSpan={staffListStore.currentTitles.length}>
                    <Alert
                      data-type="error"
                      errors={{
                        head: `${
                          staffListStore.errorMessage
                            ? staffListStore.errorMessage
                            : ""
                        }`,
                        color: "empty"
                      }}
                      className={{ container: styles.emptyAlert }}
                    />
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </>
      ) : (
        ""
      )}
      {staffListStore.page !== 1 ? (
        <div className={styles.loading}>
          {staffListStore.isLoading ? <Loading withoutText /> : ""}
        </div>
      ) : (
        <LoadedComponent
          isLoading={staffListStore.isLoading}
          errorMessage={staffListStore.errorMessage}
          actionButton={staffListStore.actionButton}
        />
      )}
    </>
  );
});

export default StaffListTable;
