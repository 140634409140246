import { Formik } from "formik";
import { without } from "lodash";
import { observer } from "mobx-react-lite";
import { Fragment, useRef, useState } from "react";
import FormWindow from "shared/ui/FormWindow";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Input } from "shared/ui/Inputs/Input";
import Switch from "shared/ui/Inputs/Switch";
import Textarea from "shared/ui/Inputs/Textarea";
import { useStores } from "stores";
import styles from "./safetyWorkCertWindow.module.scss";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { getValidationSchema } from "modules/SafetyWorkModule/SafetyWorkAdd/validation";
import { ReactComponent as Attachment } from "shared/assets/images/mainIcons/iconClip/iconClipWithoutStroke.svg";
import { fileUrl } from "stores/utils/consts";
import { ReactComponent as IconMinus } from "shared/assets/images/mainIcons/iconMinus.svg";
import StatusIcon from "shared/ui/StatusIcon";
import { Button, ButtonTheme } from "shared/ui/Button";
import { ViewField } from "shared/ui/ViewField";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";

const SafetyWorkCertWindow = () => {
  const { safetyWorkListStore } = useStores();
  const fileInputRef = useRef(null);
  const [nameCalendarOpened, setNameCalendarOpened] = useState("");
  const [requiredFields, setRequiredFields] = useState(
    safetyWorkListStore.requiredFieldsForEdit
  );
  const [withoutDateEnd, setWithoutDateEnd] = useState(false);

  const changeOpenedWindows = (name: string) => {
    setNameCalendarOpened(nameCalendarOpened === name ? "" : name);
  };

  const disabledFields = ["type", "kind", "company"];

  // оставить эти строки. Решить в задаче с багом стора
  safetyWorkListStore.isLoadingForOneCert;

  return (
    <div className={styles.background}>
      <div className={styles.modalWindow}>
        <Formik
          initialValues={{
            ...safetyWorkListStore.initialValuesForOneCertEdit,
            withoutDateEnd: 0
          }}
          enableReinitialize
          onSubmit={async (values) => {
            if (
              values["file"] === null &&
              safetyWorkListStore.initialValuesForOneCertEdit.file
            ) {
              await safetyWorkListStore.deleteFile(
                safetyWorkListStore.selectedCert
              );
            }
            await safetyWorkListStore.updateCertificate(
              safetyWorkListStore.selectedCert,
              values
            );
          }}
          validateOnBlur
          validateOnChange
          validationSchema={getValidationSchema(
            getKeys(safetyWorkListStore.initialValuesForOneCertEdit),
            requiredFields
          )}
        >
          {({
            values,
            dirty,
            isValid,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleChange
          }) => {
            return (
              <FormWindow
                title="Просмотр удостоверения"
                subtitle={`${
                  safetyWorkListStore.safetyWorkOneCert["surname"] || ""
                } ${safetyWorkListStore.safetyWorkOneCert["name"] || ""} ${
                  safetyWorkListStore.safetyWorkOneCert["patronymic"] || ""
                }`}
                setOpenWindow={() => safetyWorkListStore.setSelectedCert("")}
                saveBtnTitle="Сохранить"
                saveBtnDisabledValue={!isValid || !dirty}
                addBtnTitle="Отмена"
                addBtnImg={<IconClose />}
                addBtnDisabledValue={!dirty}
                isScroll
                saveSuccessModal={safetyWorkListStore.success}
                onSuccessModalClose={() =>
                  safetyWorkListStore.setSuccess(false)
                }
                successMessageTitle={{
                  save: "Успешно сохранено"
                }}
                isLoadingForModal={safetyWorkListStore.isLoadingForForm}
                errors={
                  getValues(safetyWorkListStore.errorMessageForOneCert)
                    .length && [safetyWorkListStore.errorMessageForOneCert]
                }
              >
                <div className={styles.inputs}>
                  {safetyWorkListStore.currentFieldsForOneCert.map((field) => {
                    const cols = safetyWorkListStore.safetyWorkColsParam[field];
                    switch (field) {
                      case "kind":
                      case "type":
                        return (
                          <Fragment key={field}>
                            <ViewField
                              title={cols["title"]}
                              value={
                                cols["directory"]?.[values[field]]?.["title"]
                              }
                              required={requiredFields.includes(field)}
                              disabled={disabledFields.includes(field)}
                            />
                          </Fragment>
                        );
                      case "company":
                        return (
                          <Fragment key={field}>
                            <ViewField
                              title={cols["title"]}
                              value={
                                safetyWorkListStore.safetyWorkOneCert?.[
                                  "title_company"
                                ]
                              }
                              required={requiredFields.includes(field)}
                              disabled={disabledFields.includes(field)}
                            />
                          </Fragment>
                        );
                      case "uid":
                        return;
                      case "date_start":
                        return (
                          <Fragment key={field}>
                            <div className={styles.dateContainer}>
                              {safetyWorkListStore.currentFieldsForOneCert.includes(
                                "date_end"
                              ) ? (
                                <label className={styles.switch}>
                                  <Switch
                                    name="withoutDateEnd"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        requiredFields.includes("date_end") &&
                                          setRequiredFields(
                                            without(requiredFields, "date_end")
                                          );
                                        setWithoutDateEnd(true);
                                        setTimeout(() => {
                                          setFieldValue("date_end", "");
                                          setFieldTouched("withoutDateEnd");
                                        }, 200);
                                      } else {
                                        !requiredFields.includes("date_end") &&
                                          safetyWorkListStore.requiredFieldsForEdit.includes(
                                            "date_end"
                                          ) &&
                                          setRequiredFields([
                                            "date_end",
                                            ...requiredFields
                                          ]);
                                        setWithoutDateEnd(false);
                                        setTimeout(() => {
                                          setFieldTouched("withoutDateEnd");
                                        }, 200);
                                      }
                                    }}
                                  />
                                  Бессрочный сертификат
                                </label>
                              ) : (
                                ""
                              )}
                              <div
                                className={
                                  safetyWorkListStore.currentFieldsForOneCert.includes(
                                    "date_end"
                                  )
                                    ? styles.doubleDateField
                                    : ""
                                }
                              >
                                <DatePickerField
                                  name={field}
                                  title={cols["title"]}
                                  isCalendarOpened={
                                    nameCalendarOpened === field
                                  }
                                  setIsCalendarOpened={() => {
                                    changeOpenedWindows(field);
                                  }}
                                  required={requiredFields.includes(field)}
                                  withClearBtn
                                  placement="right"
                                />
                                {safetyWorkListStore.currentFieldsForOneCert.includes(
                                  "date_end"
                                ) ? (
                                  <DatePickerField
                                    name={"date_end"}
                                    title={
                                      safetyWorkListStore.safetyWorkColsParam[
                                        "date_end"
                                      ]["title"]
                                    }
                                    isCalendarOpened={
                                      nameCalendarOpened === "date_end"
                                    }
                                    setIsCalendarOpened={() => {
                                      changeOpenedWindows("date_end");
                                    }}
                                    required={requiredFields.includes(
                                      "date_end"
                                    )}
                                    withClearBtn
                                    placement="left"
                                    disabled={withoutDateEnd}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Fragment>
                        );
                      case "date_end":
                        if (
                          !safetyWorkListStore.currentFieldsForOneCert.includes(
                            "date_start"
                          )
                        ) {
                          return (
                            <Fragment key={field}>
                              <DatePickerField
                                name={field}
                                title={cols["title"]}
                                isCalendarOpened={nameCalendarOpened === field}
                                setIsCalendarOpened={() => {
                                  changeOpenedWindows(field);
                                }}
                                required={requiredFields.includes(field)}
                                withClearBtn
                                placement="right"
                                disabled={withoutDateEnd}
                              />
                            </Fragment>
                          );
                        } else return;

                      case "comment":
                        return (
                          <Fragment key={field}>
                            <Textarea
                              name={field}
                              value={values[field] || ""}
                              label={cols["title"]}
                              placeholder={cols["title"]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={requiredFields.includes(field)}
                              withClearBtn
                              commentCol={
                                safetyWorkListStore.safetyWorkCols["comment"]
                              }
                            />
                          </Fragment>
                        );

                      case "file":
                        return (
                          <div className={styles.editFileInput} key={field}>
                            <div>
                              <div className={styles.title}>Файл</div>
                              <div className={styles.fileInfo}>
                                <input
                                  type="file"
                                  id="file"
                                  accept=".jpg, .jpeg, .png"
                                  name={field}
                                  ref={fileInputRef}
                                  multiple={false}
                                  onChange={(event) => {
                                    setFieldTouched(field);
                                    setFieldValue(
                                      field,
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  hidden
                                />
                                <label htmlFor="file">
                                  <div className={styles.attachment}>
                                    <Attachment className={styles.attachment} />{" "}
                                    {!values[field] ? <p>Прикрепить</p> : ""}
                                  </div>
                                </label>
                                {values[field] ? (
                                  <div className={styles.file}>
                                    {typeof values[field] === "object" ? (
                                      values[field]["name"].replace(/^.*\\/, "")
                                    ) : (
                                      <div
                                        className={styles.tooltipBlueTop}
                                        data-tooltip="Нажмите для просмотра изображения"
                                      >
                                        <a
                                          href={`${fileUrl}${values[field]}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {values[field].slice(16)}
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {values[field] ? (
                              <div className={styles.btnsForFile}>
                                {typeof values[field] === "string" ? (
                                  <div
                                    id="SafetyWorkCertWindow_downloadFile"
                                    className={classNames(
                                      styles.downloadIcon,
                                      {},
                                      [styles.tooltipBlue]
                                    )}
                                    data-tooltip="Скачать файл"
                                  >
                                    <a
                                      href={`${fileUrl}${values[field]}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <StatusIcon
                                        icon="icondownloadwb"
                                        color="bw-gray5"
                                      />
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <Button
                                  theme={ButtonTheme.ROUND}
                                  onClick={() => {
                                    if (values[field]) {
                                      setFieldTouched(field);
                                      setFieldValue(field, null);

                                      if (fileInputRef.current) {
                                        fileInputRef.current.value = "";
                                      }
                                    }
                                  }}
                                  data-tooltip="Очистить поле"
                                  className={styles.tooltipRed}
                                >
                                  <IconMinus
                                    id="SafetyWorkCertWindow_clear_fileField"
                                    className={styles.clearBtn}
                                  />
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );

                      default:
                        return (
                          <Fragment key={field}>
                            <Input
                              name={field}
                              onChange={handleChange}
                              label={cols["title"]}
                              required={requiredFields.includes(field)}
                              disabled={disabledFields.includes(field)}
                            />
                          </Fragment>
                        );
                    }
                  })}
                </div>
              </FormWindow>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default observer(SafetyWorkCertWindow);
