import styles from "./buildingOnePhotoWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";

import FormWindow from "shared/ui/FormWindow";
import StatusIcon from "shared/ui/StatusIcon";

type BuildingOnePhotoWindowProps = {
  isOpenedPhoto: string;
  setIsOpenedPhoto: (arg: string) => void;
};

const BuildingOnePhotoWindow = ({
  isOpenedPhoto,
  setIsOpenedPhoto
}: BuildingOnePhotoWindowProps) => {
  const { buildingOneStore } = useStores();

  const isDisabled = (action: "prev" | "next"): boolean => {
    if (action === "prev")
      return (
        Object.keys(buildingOneStore.selectedOne.pictures).filter(
          (id, index) => {
            return id === isOpenedPhoto && index !== 0;
          }
        )?.length === 0
      );

    if (action === "next")
      return (
        Object.keys(buildingOneStore.selectedOne.pictures).filter(
          (id, index) => {
            return (
              id === isOpenedPhoto &&
              index ===
                Object.keys(buildingOneStore.selectedOne.pictures).length - 1
            );
          }
        )?.length > 0
      );
  };

  const switchPhoto = (action: "prev" | "next") => {
    action === "prev"
      ? Object.keys(buildingOneStore.selectedOne.pictures).forEach(
          (id, index) => {
            if (id === isOpenedPhoto && index !== 0) {
              setIsOpenedPhoto(
                Object.keys(buildingOneStore.selectedOne.pictures)[index - 1]
              );
            }
          }
        )
      : Object.keys(buildingOneStore.selectedOne.pictures).forEach(
          (id, index) => {
            if (
              id === isOpenedPhoto &&
              index !==
                Object.entries(buildingOneStore.selectedOne.pictures).length - 1
            ) {
              setIsOpenedPhoto(
                Object.keys(buildingOneStore.selectedOne.pictures)[index + 1]
              );
            }
          }
        );
  };

  const handleKeyPress = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      setIsOpenedPhoto("");
    }

    if (key == "ArrowLeft" && !isDisabled("prev")) {
      switchPhoto("prev");
    }

    if (key == "ArrowRight" && !isDisabled("next")) {
      switchPhoto("next");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  });

  return (
    <div
      className={`${styles.window} ${
        Object.entries(buildingOneStore.selectedOne.pictures).filter(
          ([id]) => id === isOpenedPhoto
        )?.[0]?.[1]?.title?.length >= 75
          ? styles.window_withText
          : ""
      }`}
    >
      <FormWindow
        title="Фото объекта"
        setOpenWindow={() => setIsOpenedPhoto("")}
        withoutSendData
        isNotHaveButtons
        isScroll
        containerClassName={styles.container}
      >
        <>
          <button
            className={styles.window__prevBtn}
            id="BuildingOnePhoto_prevBtn"
            onClick={() => {
              switchPhoto("prev");
            }}
            disabled={isDisabled("prev")}
          >
            <div>
              <StatusIcon icon="iconback" color="blue-lazure" />
            </div>
          </button>
          {Object.values(buildingOneStore.selectedOne.pictures).filter(
            (image) => image.normal === isOpenedPhoto
          )?.[0]?.title ? (
            <div className={styles.window__title}>
              {
                Object.entries(buildingOneStore.selectedOne.pictures).filter(
                  ([id]) => id === isOpenedPhoto
                )?.[0]?.[1]?.title
              }
            </div>
          ) : (
            ""
          )}
          <div className={styles.window__photo}>
            <img
              className={styles.photo}
              src={buildingOneStore.selectedOne.pictures[isOpenedPhoto].normal}
            />
          </div>
          <button
            className={styles.window__nextBtn}
            id="BuildingOnePhoto_nextBtn"
            onClick={() => {
              switchPhoto("next");
            }}
            disabled={isDisabled("next")}
          >
            <div>
              <StatusIcon icon="iconnext" color="blue-lazure" />
            </div>
          </button>
        </>
      </FormWindow>
    </div>
  );
};

export default observer(BuildingOnePhotoWindow);
