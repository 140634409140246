import styles from "./staffOneConvictionWindow.module.scss";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { useStores } from "stores";

import { Fragment, useEffect, useState } from "react";

import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { format, getYear, parse } from "date-fns";
import { validationStaffOneConvitionForm } from "./validation";

import { Input } from "shared/ui/Inputs/Input";

import Textarea from "shared/ui/Inputs/Textarea";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Conviction } from "stores/StaffModule/types/Conviction";

import FormWindow from "shared/ui/FormWindow";
import Select from "shared/ui/Inputs/Select";

import { ReactComponent as IconLeft } from "shared/assets/images/mainIcons/iconLeft/iconLeftWithoutStroke.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ViewField } from "shared/ui/ViewField";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneConvictionWindowProps = {
  type: string;
  id: string;
  activeRow: Partial<Conviction>;
  setActiveRow: (el: Partial<Conviction>) => void;
  setOpenWindow: (el: { type: string; open: boolean }) => void;
};

const StaffOneConvictionWindow = ({
  id,
  type,
  setOpenWindow,
  activeRow,
  setActiveRow
}: StaffOneConvictionWindowProps) => {
  const { staffOneConvictionStore } = useStores();
  const [initialValues, setInitialValues] = useState<{
    [key: string]: string | number;
  }>({});
  const [openedName, setOpenedName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    runInAction(() => {
      staffOneConvictionStore.errorsMessage = {};
      staffOneConvictionStore.error = false;
    });

    const newConvictionObject = {};
    getEntries(activeRow).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        switch (key) {
          case "category":
            newConvictionObject[key] = value["title"] || "";
            break;
          default:
            newConvictionObject[key] = value["showname"] || value["id"] || "";
        }
      } else {
        newConvictionObject[key] = value || "";
      }
    });

    switch (type) {
      case "detail":
      case "edit":
        setInitialValues(newConvictionObject);
        break;
      case "add":
        setInitialValues(getInitialValues());
        break;
      default:
        break;
    }
  }, [type, activeRow]);

  const getInitialValues = () => {
    const newInitialValues: { [key: string]: string | number } = {};

    getKeys(staffOneConvictionStore.cols).forEach((field) => {
      switch (field) {
        case "id":
          newInitialValues[field] = id;
          break;
        case "time_create":
          newInitialValues[field] = format(new Date(), "yyyy-MM-dd");
          break;
        case "comment":
          newInitialValues[field] = "";
          break;
        default:
          switch (staffOneConvictionStore.cols[field].type) {
            case "bool":
              newInitialValues[field] = 0;
              break;
            default:
              newInitialValues[field] = "";
              break;
          }
          break;
      }
    });

    return newInitialValues;
  };

  const changeOpened = (name: string) => {
    setOpenedName(openedName === name ? "" : name);
  };

  const handleDelete = async () => {
    await staffOneConvictionStore.deleteConviction(id, initialValues.id);
  };

  useEffect(() => {
    setIsLoading(staffOneConvictionStore.isLoadingForForm);
  }, [staffOneConvictionStore.isLoadingForForm]);

  return (
    <>
      {getValues(initialValues).length ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationStaffOneConvitionForm}
          onSubmit={async (values) => {
            if (type === "add") {
              await staffOneConvictionStore.addConviction(
                id,
                values.article as string,
                values.date_start as string,
                values.punishment as string,
                values.term_type as string,
                values.comment as string
              );
            } else if (type === "edit") {
              await staffOneConvictionStore.editConviction(
                id,
                values.id as string,
                values.article as string,
                values.date_start as string,
                values.punishment as string,
                values.term_type as string,
                values.comment as string
              );
            }
          }}
          enableReinitialize
        >
          {({
            values,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            handleChange,
            isValid,
            dirty,
            isSubmitting
          }) => {
            return (
              <FormWindow
                title={(() => {
                  switch (type) {
                    case "detail":
                      return "Просмотр записи";
                    case "edit":
                      return "Редактирование записи";
                    case "add":
                      return "Добавление записи";
                  }
                })()}
                setOpenWindow={() => setOpenWindow({ type: "", open: false })}
                saveBtnTitle={
                  type === "add" || type === "edit"
                    ? "Сохранить"
                    : "Редактировать"
                }
                saveBtnOnClickFunc={() => {
                  type === "detail"
                    ? setOpenWindow({ type: "edit", open: true })
                    : handleSubmit();
                }}
                saveBtnDisabledValue={
                  (type === "add" || type === "edit") &&
                  (!isValid || !dirty || isSubmitting)
                }
                saveSuccessModal={staffOneConvictionStore.success}
                onSuccessModalClose={() =>
                  staffOneConvictionStore.setSuccess(false)
                }
                deleteBtnTitle={type === "detail" && "Удалить запись"}
                deleteBtnImg={type === "detail" && <IconClose />}
                deleteBtnOnClick={() => {
                  type === "detail" && handleDelete();
                }}
                successMessageTitle={{
                  save: "Успешно сохранено",
                  delete: "Успешно удалено"
                }}
                addBtnTitle={
                  type === "add" ? "Отмена" : type === "edit" ? "Назад" : ""
                }
                addBtnOnClickFunc={() => {
                  type === "edit" &&
                    setOpenWindow({ type: "detail", open: true });
                }}
                addBtnImg={
                  type === "edit" ? (
                    <IconLeft className={styles.backIcon} />
                  ) : type === "add" ? (
                    <IconClose />
                  ) : null
                }
                hasBackBtn={type === "edit"}
                optionalCloseFunc={() => {
                  runInAction(() => {
                    staffOneConvictionStore.errorsMessage = {};
                    staffOneConvictionStore.error = false;
                  });
                  setActiveRow({});
                  setOpenWindow({ type: "", open: false });
                }}
                errors={getValues(staffOneConvictionStore.errorsMessage)}
                isLoadingForModal={
                  isLoading || staffOneConvictionStore.isLoading
                }
                containerClassName={styles.container}
                isScroll
                {...(type === "detail" && {
                  buttonsOrder: ["delete", "save"]
                })}
              >
                <>
                  <Form className={styles.form}>
                    {getValues(staffOneConvictionStore.showFields[type]).map(
                      (field) => {
                        switch (field) {
                          case "time_create":
                          case "author":
                            if (
                              (type === "edit" || type === "add") &&
                              field === "author"
                            )
                              return;
                            else
                              return (
                                <div
                                  key={field}
                                  className={classNames("", {
                                    [styles.disabledField]: type === "detail"
                                  })}
                                >
                                  <ViewField
                                    title={
                                      staffOneConvictionStore.cols[field][
                                        "title"
                                      ]
                                    }
                                    value={getFormattedDate(
                                      String(values[field])
                                    )}
                                    disabled={
                                      field === "time_create" &&
                                      (type === "edit" || type === "add")
                                    }
                                  />
                                </div>
                              );
                          case "article":
                          case "punishment":
                          case "term_type":
                            return (
                              <Fragment key={field}>
                                <div
                                  className={classNames("", {
                                    [styles.disabledField]: type === "detail"
                                  })}
                                >
                                  {type === "detail" ? (
                                    <ViewField
                                      title={
                                        staffOneConvictionStore.cols[field]
                                          .title
                                      }
                                      value={
                                        field === "article"
                                          ? staffOneConvictionStore.cols[field][
                                              "directory"
                                            ][values[field]]?.["title"] ||
                                            staffOneConvictionStore.cols[field][
                                              "directory"
                                            ][`${values[field]}`]?.["title"] ||
                                            `ст.${
                                              activeRow[field]?.["number"]
                                            } ${
                                              activeRow[field]?.["part"]
                                                ? `ч.${activeRow[field]?.["part"]}`
                                                : ""
                                            } ${activeRow[field]?.["title"]}` ||
                                            ""
                                          : String(values[field])
                                      }
                                    />
                                  ) : (
                                    <Select
                                      required={field === "article"}
                                      name={field}
                                      title={
                                        staffOneConvictionStore.cols[field]
                                          .title
                                      }
                                      isFloating
                                      label={
                                        values[field]?.["title"]
                                          ? values[field]["title"]
                                          : ""
                                      }
                                      options={
                                        staffOneConvictionStore.cols[field][
                                          "directory"
                                        ]
                                      }
                                      onClick={(option) => {
                                        if (option) {
                                          setFieldTouched(field, true);
                                          if (field === "article") {
                                            staffOneConvictionStore.cols[field]
                                              .directory &&
                                              setFieldValue(
                                                "article",
                                                option.newname
                                              );
                                            setFieldValue(
                                              "category",
                                              option["category"]
                                            );
                                          } else {
                                            setFieldValue(field, option.title);
                                          }
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                                {field === "article" && type === "detail" && (
                                  <div className={styles.disabledField}>
                                    <ViewField
                                      title="Категория"
                                      value={
                                        staffOneConvictionStore.cols[field][
                                          "directory"
                                        ][values[field]]?.["category"]
                                      }
                                    />
                                  </div>
                                )}
                              </Fragment>
                            );
                          case "date_start":
                            return (
                              <div
                                key={field}
                                className={classNames("", {
                                  [styles.disabledField]: type === "detail"
                                })}
                              >
                                {type === "detail" ? (
                                  <ViewField
                                    title={
                                      staffOneConvictionStore.cols[field][
                                        "title"
                                      ]
                                    }
                                    value={getFormattedDate(
                                      String(values[field])
                                    )}
                                  />
                                ) : (
                                  <DatePickerField
                                    name={field}
                                    dateFormat={"yyyy"}
                                    title={
                                      staffOneConvictionStore.cols[field][
                                        "title"
                                      ]
                                    }
                                    isCalendarOpened={openedName === field}
                                    setIsCalendarOpened={() => {
                                      changeOpened(field);
                                    }}
                                    minDate={parse(
                                      String(getYear(new Date()) - 70 + 14),
                                      "yyyy",
                                      new Date()
                                    )}
                                    maxDate={
                                      new Date(new Date().getFullYear(), 11, 31)
                                    }
                                    required
                                  />
                                )}
                              </div>
                            );
                          case "comment":
                            return (
                              <div
                                key={field}
                                className={classNames("", {
                                  [styles.disabledField]: type === "detail"
                                })}
                              >
                                <Textarea
                                  name={field}
                                  value={
                                    (values[field]?.["title"]
                                      ? values[field]["title"]
                                      : values[field]) || ""
                                  }
                                  placeholder={
                                    staffOneConvictionStore.cols[field]["title"]
                                  }
                                  label={
                                    staffOneConvictionStore.cols[field]["title"]
                                  }
                                  disabled={
                                    !(
                                      type !== "detail" &&
                                      field.includes("comment")
                                    )
                                  }
                                  commentCol={
                                    field === "comment"
                                      ? staffOneConvictionStore.cols[field]
                                      : ""
                                  }
                                />
                              </div>
                            );
                          case "uid":
                          case "id":
                            return null;
                          default:
                            if (staffOneConvictionStore.cols[field]) {
                              return (
                                <div
                                  className={classNames("", {
                                    [styles.disabledField]: type === "detail"
                                  })}
                                  key={field}
                                >
                                  <Input
                                    disabled={
                                      type === "detail" || field === "category"
                                    }
                                    name={field}
                                    label={
                                      staffOneConvictionStore.cols[field][
                                        "title"
                                      ]
                                    }
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                  />
                                </div>
                              );
                            } else return;
                        }
                      }
                    )}
                  </Form>
                </>
              </FormWindow>
            );
          }}
        </Formik>
      ) : (
        ""
      )}
    </>
  );
};

export default observer(StaffOneConvictionWindow);
