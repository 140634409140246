import { useStores } from "stores";
import styles from "./cancellationModal.module.scss";
import { Formik } from "formik";
import Select from "shared/ui/Inputs/Select";
import FormWindow from "shared/ui/FormWindow";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

type CancellationModalProps = {
  id: string;
  setIsOpenModal: () => void;
};

const CancellationModal = ({ setIsOpenModal }: CancellationModalProps) => {
  const { aregisterOneStore } = useStores();

  const setIsOpenedWindow = () => setIsOpenModal();

  return (
    <Formik
      initialValues={{ kc: null }}
      enableReinitialize
      onSubmit={() => null}
    >
      {({ dirty }) => (
        <FormWindow
          title="Тип аннуляции"
          setOpenWindow={setIsOpenedWindow}
          saveBtnTitle="Аннулировать"
          saveBtnDisabledValue={!dirty}
          addBtnTitle="Отмена"
          addBtnImg={<IconClose />}
          containerClassName={styles.container}
        >
          <Select
            name="kc"
            isFloating
            title="Тип аннуляции"
            options={
              aregisterOneStore.selects.cancellation_type as Record<
                string,
                { title: string }
              >
            }
            valueName="title"
            required
          />
        </FormWindow>
      )}
    </Formik>
  );
};

export default CancellationModal;
