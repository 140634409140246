import styles from "./buildingOneWorkshiftForm.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from "stores";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Form from "react-bootstrap/Form";
import { Formik } from "formik";

import Select from "shared/ui/Inputs/Select";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Textarea from "shared/ui/Inputs/Textarea";
import FormWindow from "shared/ui/FormWindow";

import { validationBuildingOneWorkshiftForm } from "./validation";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

type BuildingOneWorkshiftFormProps = {
  date?: string;
};

const BuildingOneWorkshiftForm = ({ date }: BuildingOneWorkshiftFormProps) => {
  const { buildingOneAddWorkshiftFormStore, buildingOneTimesheetStore } =
    useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const navigateNewWorkshift = (id: string) => {
    navigate(`../../workshift/id=${id}/staff`);
  };
  const { id } = useParams();
  const location = useLocation();

  const changeOpenedListName = (name: string) => {
    openedListName === name ? setOpenedListName(null) : setOpenedListName(name);
  };

  useEffect(() => {
    isLoading !== buildingOneAddWorkshiftFormStore.isLoadingForForm &&
      setIsLoading(buildingOneAddWorkshiftFormStore.isLoadingForForm);
  }, [
    buildingOneAddWorkshiftFormStore.isLoadingForForm,
    buildingOneAddWorkshiftFormStore.successBuilding
  ]);

  useEffect(() => {
    buildingOneAddWorkshiftFormStore.setIsOpenedWorkshiftForm();
  }, []);

  // при вызове формы из меню почему то id из params undefined, поэтому для таких случаев
  // достаём id объекта из ссылки самостоятельно
  const building_id = id || location.pathname?.split("=")[1]?.split("/")[0];

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Formik
        initialValues={{
          building_id,
          date: null,
          comment: ""
        }}
        onSubmit={(values) => {
          Promise.all([
            buildingOneAddWorkshiftFormStore.addNewWorkshift(values)
          ]).then(() => {
            if (
              buildingOneAddWorkshiftFormStore.errorsMessage &&
              !Object.values(buildingOneAddWorkshiftFormStore.errorsMessage)
                .length
            ) {
              if (buildingOneTimesheetStore.allOpenedTabels[building_id]) {
                Promise.all([
                  buildingOneTimesheetStore.getTabel(
                    building_id,
                    buildingOneTimesheetStore.year[building_id],
                    buildingOneTimesheetStore.month[building_id]
                  )
                ]).then(() => {
                  buildingOneAddWorkshiftFormStore.setOpenedWindow();
                });
              } else {
                buildingOneAddWorkshiftFormStore.setOpenedWindow();
              }

              buildingOneAddWorkshiftFormStore.newWorkshiftId?.length &&
                navigateNewWorkshift(
                  buildingOneAddWorkshiftFormStore.newWorkshiftId
                );
            }
          });
        }}
        validateOnBlur
        validationSchema={validationBuildingOneWorkshiftForm}
        enableReinitialize
      >
        {({ values, dirty, isValid, setFieldValue }) => {
          if (date && values.date === null) {
            setFieldValue("date", date);
          }
          return (
            <FormWindow
              title="Добавление смены"
              setOpenWindow={() =>
                buildingOneAddWorkshiftFormStore.setOpenedWindow()
              }
              saveBtnTitle="Добавить"
              saveBtnDisabledValue={!dirty || !isValid}
              saveSuccessModal={buildingOneAddWorkshiftFormStore.success}
              onSuccessModalClose={() =>
                buildingOneAddWorkshiftFormStore.setSuccess(false)
              }
              addBtnTitle="Отмена"
              addBtnImg={<IconClose />}
              errors={buildingOneAddWorkshiftFormStore.errorsMessage}
              isLoadingForModal={isLoading}
              containerClassName={styles.container}
            >
              <Form className={styles.form}>
                <div className={styles.fieldsGroup}>
                  <div>
                    <Select
                      name="building_id"
                      options={buildingOneAddWorkshiftFormStore.buildingOptions}
                      title={
                        buildingOneAddWorkshiftFormStore.cols["building"]?.title
                      }
                      label={
                        buildingOneAddWorkshiftFormStore.buildingsList[
                          values.building_id
                        ]?.title || "Загрузка данных..."
                      }
                      isFloating
                      required
                      valueName="id"
                    />
                  </div>

                  <div>
                    <DatePickerField
                      name="date"
                      isCalendarOpened={openedListName === "date"}
                      setIsCalendarOpened={() => changeOpenedListName("date")}
                      title={
                        buildingOneAddWorkshiftFormStore.cols["date_report"]
                          ?.title
                      }
                      required
                    />
                  </div>
                </div>
                <Textarea
                  name="comment"
                  label={
                    buildingOneAddWorkshiftFormStore.cols["comment"]?.title
                  }
                  placeholder={
                    buildingOneAddWorkshiftFormStore.cols["comment"]?.title
                  }
                  value={values["comment"]}
                  commentCol={buildingOneAddWorkshiftFormStore.cols["comment"]}
                />
              </Form>
            </FormWindow>
          );
        }}
      </Formik>
    </ErrorBoundary>
  );
};

export default observer(BuildingOneWorkshiftForm);
