import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import styles from "./buttonsGroupForSettings.module.scss";
import { classNames } from "shared/utils/helpers/classNames";

type ButtonsGroupForSettingsProps = {
  saveBtnTitle?: string;
  saveBtnDisabled?: boolean;
  saveBtnOnClick?: () => void;
  addBtnTitle?: string;
  addBtnDisabled?: boolean;
  addBtnOnClick?: () => void;
  addBtnImg?: JSX.Element;
  cancelBtnTitle?: string;
  cancelBtnDisabled?: boolean;
  cancelBtnOnClick?: () => void;
  cancelBtnImg?: JSX.Element;
  deleteBtnTitle?: string;
  deleteBtnDisabled?: boolean;
  deleteBtnOnClick?: () => void;
  deleteBtnImg?: JSX.Element;
  className?: {
    cancelBtn?: string;
    addBtn?: string;
    saveBtn?: string;
    deleteBtn?: string;
  };
  buttonsOrder?: ("save" | "add" | "cancel" | "delete")[];
};

const ButtonsGroupForSettings = ({
  saveBtnTitle,
  saveBtnDisabled,
  saveBtnOnClick,

  addBtnTitle,
  addBtnDisabled,
  addBtnOnClick,
  addBtnImg,

  cancelBtnTitle,
  cancelBtnDisabled,
  cancelBtnOnClick,
  cancelBtnImg,

  deleteBtnTitle,
  deleteBtnDisabled,
  deleteBtnOnClick,
  deleteBtnImg,

  className,
  buttonsOrder = ["delete", "save", "add", "cancel"]
}: ButtonsGroupForSettingsProps) => {
  const buttonsConfig = {
    save: saveBtnTitle ? (
      <Button
        key="save"
        type="button"
        className={classNames("", {}, [className?.saveBtn])}
        size={ButtonSize.L}
        disabled={saveBtnDisabled}
        onClick={saveBtnOnClick}
        id="ButtonsGroupForSettings_saveBtn"
      >
        {saveBtnTitle}
      </Button>
    ) : null,
    add: addBtnTitle ? (
      <Button
        key="add"
        type="button"
        className={classNames(styles.addBtn, {}, [className?.addBtn])}
        size={ButtonSize.L}
        theme={ButtonTheme.SECONDARY}
        disabled={addBtnDisabled}
        onClick={addBtnOnClick}
        id="ButtonsGroupForSettings_addBtnTitle"
      >
        {addBtnImg ? addBtnImg : null}
        <p>{addBtnTitle}</p>
      </Button>
    ) : null,
    cancel: cancelBtnTitle ? (
      <Button
        key="cancel"
        type="button"
        className={classNames(styles.cancelBtn, {}, [className?.cancelBtn])}
        size={ButtonSize.L}
        theme={ButtonTheme.CANCELLED}
        disabled={cancelBtnDisabled}
        onClick={cancelBtnOnClick}
        id="ButtonsGroupForSettings_cancelBtnTitle"
      >
        {cancelBtnImg ? cancelBtnImg : null}
        <p>{cancelBtnTitle}</p>
      </Button>
    ) : null,
    delete: deleteBtnTitle ? (
      <Button
        key="delete"
        type="button"
        className={classNames(styles.deleteBtn, {}, [className?.deleteBtn])}
        size={ButtonSize.L}
        theme={ButtonTheme.CANCELLED}
        disabled={deleteBtnDisabled}
        onClick={deleteBtnOnClick}
        id="ButtonsGroupForSettings_deleteBtnTitle"
      >
        {deleteBtnImg ? deleteBtnImg : null}
        <p>{deleteBtnTitle}</p>
      </Button>
    ) : null
  };

  return (
    <div className={styles.btnGroup}>
      {buttonsOrder.map((buttonKey) => buttonsConfig[buttonKey])}
    </div>
  );
};

export default ButtonsGroupForSettings;
