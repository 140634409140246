import { useState } from "react";
import styles from "./formWindowHistory.module.scss";
import StatusIcon from "shared/ui/StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";

type FormWindowHistoryProps = {
  title: string;
  children: React.ReactNode;
};

const FormWindowHistory = ({ title, children }: FormWindowHistoryProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.historyContainer}>
      <div onClick={() => setIsOpen(!isOpen)} className={styles.header}>
        <StatusIcon
          icon="iconcollapsestroke"
          color="bw-gray6"
          className={classNames(styles.iconCollapse, {
            [styles.iconCollapseOpened]: isOpen,
            [styles.iconCollapseClosed]: !isOpen
          })}
        />
        <p className={styles.title}>{title}</p>
      </div>
      {isOpen && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default FormWindowHistory;
