import { useStores } from "stores/index";

import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";

import Operation from "shared/assets/images/menuIcons/iconDefault/Operation.svg";
import OperationSelected from "shared/assets/images/menuIcons/iconSelected/Operation.svg";

import Comments from "shared/assets/images/menuIcons/iconDefault/Comments.svg";
import CommentsSelected from "shared/assets/images/menuIcons/iconSelected/Comments.svg";

import History from "shared/assets/images/menuIcons/iconDefault/History.svg";
import HistorySelected from "shared/assets/images/menuIcons/iconSelected/History.svg";

import EditOperations from "shared/assets/images/menuIcons/iconDefault/EditOperations.svg";
import EditOperationsSelected from "shared/assets/images/menuIcons/iconSelected/EditOperations.svg";
import { observer } from "mobx-react-lite";

const SalarySectionOneOperation = () => {
  const { menuStore } = useStores();

  const tabId = menuStore.tabId;

  const overview = [
    {
      text: "Описание",
      to: `/salary/id=${tabId}`,
      moduleName: "salaryOne",
      submoduleName: "salaryOne",
      defaultIcon: Operation,
      selectedIcon: OperationSelected
    },
    {
      text: "Комментарии",
      to: `/salary/id=${tabId}/comments`,
      moduleName: "salaryOne",
      submoduleName: "salaryOneComments",
      defaultIcon: Comments,
      selectedIcon: CommentsSelected
    },
    {
      text: "История",
      to: `/salary/id=${tabId}/history`,
      moduleName: "salaryOne",
      submoduleName: "salaryOneHistory",
      defaultIcon: History,
      selectedIcon: HistorySelected
    }
  ];

  const actions = [
    {
      text: "Редактировать",
      to: `/salary/id=${tabId}/edit`,
      moduleName: "salaryOne",
      submoduleName: "salaryOneEdit",
      defaultIcon: EditOperations,
      selectedIcon: EditOperationsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item.text}
            to={item.to}
            text={item.text}
            defaultIcon={item.defaultIcon}
            selectedIcon={item.selectedIcon}
            moduleName={item.moduleName}
            submoduleName={item.submoduleName}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {actions.map((item) => (
          <MenuButton
            key={item.text}
            to={item.to}
            text={item.text}
            defaultIcon={item.defaultIcon}
            selectedIcon={item.selectedIcon}
            moduleName={item.moduleName}
            submoduleName={item.submoduleName}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default observer(SalarySectionOneOperation);
