import { Button, ButtonSize } from "shared/ui/Button";
import styles from "./formWindowHeader.module.scss";

import StatusIcon from "shared/ui/StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";

type FormWindowHeaderProps = {
  title: string;
  subtitle: string;
  closeWindowFunc: () => void;
  isScrolled?: boolean;
};

const FormWindowHeader = ({
  title,
  subtitle,
  closeWindowFunc,
  isScrolled
}: FormWindowHeaderProps) => {
  return (
    <div
      className={classNames(styles.header, {
        [styles.scrolled]: isScrolled
      })}
    >
      <div className={styles.containerTitle}>
        <div className={styles.title}>{title}</div>
        {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : ""}
      </div>

      <Button size={ButtonSize.S} onClick={closeWindowFunc} type="button">
        <StatusIcon icon="iconclose" color="bw-white" />
      </Button>
    </div>
  );
};

export default FormWindowHeader;
