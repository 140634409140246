import styles from "./staffOneEdit.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import StaffOneEditForm from "features/StaffOneEditForm/index";

import StaffOneEditSetting from "./StaffOneEditSetting/index";

import PageTitle from "shared/ui/PageTitle";
import Submenu from "shared/ui/Submenu/index";
import { IRecordsForEdit } from "stores/StaffModule/types/RecordsForEdit";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { isEmpty } from "lodash";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";

const StaffOneEdit = () => {
  const { menuStore, staffOneEditStore } = useStores();

  const { id, edit } = useParams();
  const [activeGroup, setActiveGroup] = useState<{
    data?: Partial<IRecordsForEdit>;
    sort?: number;
    icon?: string;
    title: string;
    id?: string;
  }>({ id: "", title: "" });

  const navigate = useNavigate();
  const navigateEdit = (objID: string) => {
    navigate(`../staff/id=${id}/edit=${objID}`);
  };

  const setSelectedEditGroup = (group: string) => {
    navigateEdit(group);
    if (group !== "appsettings") {
      const values = staffOneEditStore.getValuesForEdit(group);
      setActiveGroup({ ...staffOneEditStore.groups[group], data: values });
    } else setActiveGroup(staffOneEditStore.groups[group]);
  };

  useEffect(() => {
    staffOneEditStore.setSelectedOneForEdit(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffEdit");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/edit=${edit}`
    });
  }, [id]);

  useEffect(() => {
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/edit=${edit}`
    });
  }, [edit]);

  useEffect(() => {
    !isEmpty(staffOneEditStore.groups) &&
      setSelectedEditGroup(
        edit === "appsettings"
          ? "appsettings"
          : staffOneEditStore.groups[edit]
          ? edit
          : getKeys(staffOneEditStore.groups)[0]
      );
  }, [
    getValues(staffOneEditStore.groups).length,
    staffOneEditStore.selectedOneForEdit,
    staffOneEditStore.selectedCompanyId[id],
    staffOneEditStore.selectedTypeOfJob[id]
  ]);

  return (
    <LoadedComponent
      isLoading={staffOneEditStore.isLoading}
      error={staffOneEditStore.error}
      errorMessage={staffOneEditStore.errorText[id]}
    >
      <>
        <PageTitle title="Редактирование сотрудника" leftCol />
        <div className={styles.main}>
          <div className={styles.menu}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Submenu
                title="Что хотите редактировать?"
                array={getValues(staffOneEditStore.groups)}
                arrayItemId="id"
                arrayItemTitle="title"
                selectedItem={activeGroup}
                setSelectedItem={setSelectedEditGroup}
                funcForItem={() => staffOneEditStore.setErrorsMessage(id, {})}
                withIcons
              />
            </ErrorBoundary>
          </div>
          <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[edit]}>
            {edit === "appsettings" ? (
              <StaffOneEditSetting />
            ) : (
              <StaffOneEditForm
                cols={getValues(activeGroup["columns"])}
                data={activeGroup["data"] ? activeGroup["data"] : {}}
              />
            )}
          </ErrorBoundary>
        </div>
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneEdit);
