import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js";

// функция для форматирования телефонных номеров, принимает как число, так и строку
export const formatPhoneNumber = (
  value: number | string,
  withoutInvalidPhone?: "withoutInvalidPhone"
): string => {
  let str = String(value);
  let phone: Partial<PhoneNumber> = {};

  const validPhone = str.length >= 11 && parsePhoneNumberFromString(`+${str}`);
  const russianNationalValidPhone =
    str.length === 11 && (str[0] === "8" || str[0] === "9");
  const nationalPhoneWithoutCountryCode = str[0] === "9" && str.length === 10;
  const invalidPhoneWithDot = str[1] === "." && str.length > 11;
  const invalidPhoneWithPlus = str[0] === "+" && str.length > 1;

  if (validPhone) {
    phone = parsePhoneNumberFromString(
      `+${str}`,
      validPhone.country || validPhone.getPossibleCountries()?.[0]
    );
  } else if (russianNationalValidPhone) {
    phone = parsePhoneNumberFromString(`+7${`${str}`.substring(1)}`);
  } else if (nationalPhoneWithoutCountryCode) {
    phone = parsePhoneNumberFromString(`+7${str}`);
  } else if (invalidPhoneWithDot) {
    if (parsePhoneNumberFromString(`+7 ${str.substring(2, 12)}`)) {
      phone = parsePhoneNumberFromString(`+7 ${str.substring(2, 12)}`);
    }
  } else if (invalidPhoneWithPlus) {
    const newStr = str.replace(/\+/g, "");
    if (parsePhoneNumberFromString(`+${newStr}`)) {
      phone = parsePhoneNumberFromString(`+${newStr}`);
    } else str = newStr;
  }

  if (Object.values(phone).length) {
    const code = phone["countryCallingCode"];
    const country = phone["country"];

    return country === "RU" || country === "KZ" || code === "7"
      ? `+${code} (${phone["nationalNumber"].slice(0, 3)}) ${phone[
          "nationalNumber"
        ].slice(3, 6)}-${phone["nationalNumber"].slice(6, 8)}-${phone[
          "nationalNumber"
        ].slice(8, phone["nationalNumber"].length)}`
      : phone.formatInternational();
  } else if (!withoutInvalidPhone && (str.length < 11 || str.length > 15)) {
    if (str[0] === "7") {
      const withCode = `+${str[0]} (${str.slice(1, 4)})`;
      const withCodeAndThreeNumbers = `${withCode} ${str[4]}${str[5]}${str[6]}`;
      const withCodeAndFiveNumbers = `${withCodeAndThreeNumbers}-${str[7]}${str[8]}`;
      switch (str.length) {
        case 1:
          return `+${str}`;
        case 2:
          return `+${str[0]} ${str[1]}`;
        case 3:
          return `+${str[0]} ${str[1]}${str[2]}`;
        case 4:
          return withCode;
        case 5:
          return `${withCode} ${str[4]}`;
        case 6:
          return `${withCode} ${str[4]}${str[5]}`;
        case 7:
          return withCodeAndThreeNumbers;
        case 8:
          return `${withCodeAndThreeNumbers}-${str[7]}`;
        case 9:
          return withCodeAndFiveNumbers;
        case 10:
          return `${withCodeAndFiveNumbers}-${str[9]}`;
        default:
          return str;
      }
    } else {
      const threeNumbers = `${str[0]}${str[1]}${str[2]}`;
      switch (str.length) {
        case 1:
        case 2:
          return str;
        case 3:
          return threeNumbers;
        case 4:
          return `${threeNumbers}-${str[3]}`;
        case 5:
          return `${threeNumbers}-${str[3]}${str[4]}`;
        case 6:
          return `${threeNumbers}-${str[3]}${str[4]}-${str[5]}`;
        case 7:
          return `${threeNumbers}-${str[3]}${str[4]}-${str[5]}${str[6]}`;
        default:
          return str;
      }
    }
  } else if (withoutInvalidPhone) return "";
};
