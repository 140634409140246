import { Formik } from "formik";
import FormWindow from "shared/ui/FormWindow";
import styles from "./filterMainWindow.module.scss";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ReactComponent as IconCloseRed } from "shared/assets/images/mainIcons/iconsClose/iconCloseRed.svg";
import { ReactComponent as IconCloseGrey } from "shared/assets/images/mainIcons/iconsClose/iconCloseGray.svg";
import { Col } from "stores/utils/types/Col";
import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";
import { cloneDeep } from "lodash";
import { Input } from "shared/ui/Inputs/Input";
import { FilterType } from "stores/utils/types/FilterType";
import { validation } from "./validation";
import { ListManager } from "react-beautiful-dnd-grid";
import Scrollbars from "react-custom-scrollbars-2";
import { useEffect } from "react";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonTheme } from "shared/ui/Button";
import { Option } from "shared/ui/Option";
import Tooltip from "shared/ui/Tooltip";

type FilterMainWindowProps = {
  setIsMenuOpened: () => void;
  title: string;
  subtitle?: string;
  isColumns?: boolean;
  isMultiSelect?: boolean;
  valuesSaveFilter?: FilterType;
  valuesArray?: string[];
  defaultArray?: string[];
  directory?: Col["directory"] | { [key: string]: Col };
  updateItems?: (arg: string[]) => void;
  setNewFilter?: (arg: FilterType) => void;
  selectMulti?: string[];
  isSearchWithPagination?: boolean;
  pageSelectsInfo?: Record<
    string,
    {
      page: number;
      prevPage: number;
      maxPage: number;
      searchValue: string;
      isLoading: boolean;
      setSearchValue: (value: string) => void;
      setPage: (value: number) => void;
      getList: () => void;
    }
  >;
  field?: string;
  dictForArray?: Record<string, string>;
  setDictForArray?: (value: Record<string, string>) => void;
  dateRangeFields?: Record<string, string[]>;
};

type ArrayOrderValues = {
  title: string;
  order: number;
}[];

const FilterMainWindow = ({
  setIsMenuOpened,
  title,
  subtitle,
  isColumns,
  isMultiSelect,
  valuesSaveFilter,
  valuesArray,
  defaultArray,
  directory,
  updateItems,
  setNewFilter,
  selectMulti,
  isSearchWithPagination,
  pageSelectsInfo,
  field,
  dictForArray,
  setDictForArray,
  dateRangeFields
}: FilterMainWindowProps) => {
  const getInitialValues = () => {
    return valuesArray.map((title, index) => {
      return { title, order: index };
    });
  };

  // сбрасываем список, пагинация которого происходит с бэка, до начального при наличии searchValue
  useEffect(() => {
    if (pageSelectsInfo && field) {
      Object.entries(pageSelectsInfo).forEach(([key, value]) => {
        if (key === field && value.searchValue) {
          value.setSearchValue("");
          value.getList();
        }
      });
    }
  }, []);

  return (
    <Formik
      initialValues={isMultiSelect ? getInitialValues() : valuesSaveFilter}
      enableReinitialize
      onSubmit={() => {
        return;
      }}
      validationSchema={validation}
    >
      {({
        dirty,
        values,
        setValues,
        isValid,
        handleBlur,
        handleChange,
        setFieldValue
      }) => {
        // функция возвращает JSX-элемент одной колонки/параметра
        const getArrayList = (title: string) => {
          return (
            <Option
              key={title}
              title={
                directory[title]
                  ? directory[title]["title"]
                  : Array.isArray(directory)
                  ? dictForArray[title]
                  : title
              }
              onClick={() => {
                const arrayFilter =
                  Array.isArray(values) &&
                  values.filter((item) => item.title !== title);
                setValues(arrayFilter);
              }}
            />
          );
        };

        // функция возвращает JSX-элемент списка колонок
        const ListElement = ({ title }: { title: string }) => {
          return getArrayList(title);
        };

        //функция сортировки колонок по order при их перемещении относительно друг друга
        const sortColumns = (list: ArrayOrderValues) => {
          setValues(
            list.slice().sort((first, second) => first.order - second.order)
          );
        };

        // функция для реализации drag and drop
        // принимает в себя индекс начального положения и индекс конечного положения
        // на каждом сравнении destinationIndex задает соответствующий порядок нового массива колонок
        const handleOnDragEnd = (
          sourceIndex: number,
          destinationIndex: number
        ) => {
          if (destinationIndex === sourceIndex) {
            return;
          }

          const list = [...(values as ArrayOrderValues)];
          if (destinationIndex === 0) {
            list[sourceIndex].order = list[0].order - 1;
            sortColumns(list);
            return;
          }
          if (destinationIndex === list.length - 1) {
            list[sourceIndex].order = list[list.length - 1].order + 1;
            sortColumns(list);
            return;
          }
          if (destinationIndex < sourceIndex) {
            list[sourceIndex].order =
              (list[destinationIndex].order +
                list[destinationIndex - 1].order) /
              2;
            sortColumns(list);
            return;
          }
          list[sourceIndex].order =
            (list[destinationIndex].order + list[destinationIndex + 1].order) /
            2;
          sortColumns(list);
        };

        // функция возвращает JSX-элемент списка дефолтных полей
        const getDefaultList = () => {
          return (
            <div className={styles.cols}>
              {defaultArray.map((key) => (
                <Tooltip
                  key={key}
                  text="Обязательное поле"
                  color="blue-lazure"
                  placement="bottom"
                  withoutFlip
                >
                  <Option
                    key={title}
                    title={directory[key] ? directory[key]["title"] : key}
                    required
                  />
                </Tooltip>
              ))}
            </div>
          );
        };

        return (
          <div className={styles.formWindowBlock}>
            <FormWindow
              title={title}
              subtitle={subtitle}
              setOpenWindow={() => setIsMenuOpened()}
              saveBtnTitle={isMultiSelect ? "Готово" : "Сохранить"}
              saveBtnOnClickFunc={() => {
                Array.isArray(values)
                  ? updateItems(
                      values
                        .map((item) => item.title)
                        .concat(defaultArray && !isColumns ? defaultArray : [])
                    )
                  : setNewFilter(values);
                setIsMenuOpened();
              }}
              saveBtnDisabledValue={!dirty || !isValid}
              addBtnTitle="Отмена"
              addBtnImg={<IconClose />}
              isScroll
              fromOverlayWindow
            >
              <div className={styles.container}>
                {Array.isArray(values) ? (
                  <>
                    <div className={styles.moveItemBlock}>
                      <div className={styles.multiSelectHeader}>
                        <p>Добавлено: {values.length}</p>
                        {values.length || defaultArray?.length ? (
                          <Button
                            className={styles.clearBtn}
                            theme={ButtonTheme.CLEAR}
                            disabled={!values.length}
                            id="FilterMainWindow_clearValueBtn"
                            onClick={() => setValues([])}
                          >
                            Очистить
                            {values.length ? (
                              <IconCloseRed />
                            ) : (
                              <IconCloseGrey />
                            )}
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                      <Scrollbars
                        width="100%"
                        autoHeight
                        autoHeightMax="250px"
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                      >
                        <>
                          {isColumns ? (
                            <div className={styles.colsForDragNDrop}>
                              <ListManager
                                items={values}
                                direction="horizontal"
                                maxItems={3}
                                render={(item) => (
                                  <ListElement title={item.title} />
                                )}
                                onDragEnd={handleOnDragEnd}
                              />
                              {defaultArray ? getDefaultList() : ""}
                            </div>
                          ) : (
                            <div className={styles.cols}>
                              {values.map((param) => getArrayList(param.title))}
                              {defaultArray ? getDefaultList() : ""}
                            </div>
                          )}
                        </>
                      </Scrollbars>
                      {isColumns ? (
                        <div className={styles.helpingInfoText}>
                          Зажмите и перетащите, чтобы поменять порядок столбцов
                          в таблице
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <p className={styles.optionsListBlockTitle}>
                      Выберите одно или несколько значений
                    </p>
                    <div className={styles.optionList}>
                      <ItemsScrollBoard
                        options={directory as Col["directory"]}
                        values={values
                          .map((item) => item.title)
                          .concat(defaultArray ? defaultArray : [])}
                        isItemBtnMode
                        addItem={(value) => {
                          const array = cloneDeep(values);
                          array.push({
                            title: value["newname"] as string,
                            order: array.length - 1
                          });
                          if (Array.isArray(directory)) {
                            setDictForArray({
                              ...dictForArray,
                              ...{
                                [value["newname"] as string]: value["title"]
                              }
                            });
                          }
                          setValues(array);
                        }}
                        isSearchWithPagination={isSearchWithPagination}
                        page={pageSelectsInfo?.[field]?.["page"]}
                        prevPage={pageSelectsInfo?.[field]?.["prevPage"]}
                        maxPage={pageSelectsInfo?.[field]?.["maxPage"]}
                        searchValue={pageSelectsInfo?.[field]?.["searchValue"]}
                        isLoading={pageSelectsInfo?.[field]?.["isLoading"]}
                        setSearchValue={
                          pageSelectsInfo?.[field]?.["setSearchValue"]
                        }
                        setPage={pageSelectsInfo?.[field]?.["setPage"]}
                        getList={pageSelectsInfo?.[field]?.["getList"]}
                      />
                    </div>
                  </>
                ) : values ? (
                  <>
                    <div className={styles.titleBlock}>
                      <p>
                        Придумайте короткое название для фильтра (не более 100
                        символов).
                      </p>
                      <Input
                        name="title"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        label="Название"
                      />
                    </div>

                    <p className={styles.optionsListBlockTitle}>Параметры:</p>
                    <div className={styles.paramsBlock}>
                      <>
                        {Object.entries(values.filter).map(([key, value]) => {
                          if (
                            (((typeof value === "string" ||
                              Array.isArray(value)) &&
                              value.length) ||
                              (value && Object.values(value).length)) &&
                            dateRangeFields &&
                            !Object.values(dateRangeFields).flat().includes(key)
                          ) {
                            return (
                              <div key={key}>
                                <p
                                  className={`${styles.helpingInfoText} ${styles.titleParam}`}
                                >
                                  {directory[key]?.["title"]}
                                </p>
                                {(() => {
                                  const field = directory[key];
                                  if (field?.directory) {
                                    if (selectMulti.includes(key)) {
                                      return (
                                        <div className={styles.cols}>
                                          {(value as string[]).map((name) => (
                                            <Option
                                              key={name}
                                              title={
                                                Array.isArray(
                                                  field["directory"]
                                                )
                                                  ? dictForArray[name]
                                                  : field["directory"][name]
                                                  ? field["directory"][name][
                                                      "title"
                                                    ]
                                                  : name
                                              }
                                              onClick={() => {
                                                const arrayFilter = (
                                                  value as string[]
                                                ).filter(
                                                  (item) => item !== name
                                                );
                                                setFieldValue(
                                                  `filter[${key}]`,
                                                  arrayFilter
                                                );
                                              }}
                                            />
                                          ))}
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <Option
                                          title={
                                            field["directory"][value]?.["title"]
                                          }
                                          onClick={() => {
                                            setFieldValue(
                                              `filter[${key}]`,
                                              null
                                            );
                                          }}
                                        />
                                      );
                                    }
                                  }
                                  if (field?.type === "bool") {
                                    return (
                                      <Option
                                        title={value === "1" ? "Да" : "Нет"}
                                        onClick={() => {
                                          setFieldValue(`filter[${key}]`, null);
                                        }}
                                      />
                                    );
                                  }

                                  return (
                                    <Option
                                      title={value as string}
                                      onClick={() => {
                                        setFieldValue(`filter[${key}]`, null);
                                      }}
                                    />
                                  );
                                })()}
                              </div>
                            );
                          }
                        })}
                        {dateRangeFields &&
                          Object.entries(dateRangeFields).map(
                            ([key, value]) => {
                              if (
                                value.every(
                                  (field) =>
                                    typeof values.filter?.[field] ===
                                      "string" &&
                                    (values.filter?.[field] as string).length
                                )
                              ) {
                                return (
                                  <div key={key}>
                                    <p
                                      className={`${styles.helpingInfoText} ${styles.titleParam}`}
                                    >
                                      {directory[key]?.["title"]}
                                    </p>
                                    <Option
                                      title={`${getFormattedDate(
                                        values.filter[value[0]] as string
                                      )} - ${getFormattedDate(
                                        values.filter[value[1]] as string
                                      )}`}
                                      onClick={() => {
                                        value.forEach((field) => {
                                          setFieldValue(
                                            `filter[${field}]`,
                                            null
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              }
                            }
                          )}
                      </>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </FormWindow>
          </div>
        );
      }}
    </Formik>
  );
};

export default FilterMainWindow;
