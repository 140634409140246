import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
import FormWindow from "shared/ui/FormWindow";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import { useStores } from "stores";
import styles from "./staffRetentionTableHistoryWindow.module.scss";
import StatusIcon from "shared/ui/StatusIcon";
import { fileUrl } from "stores/utils/consts";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";
import Alert from "shared/ui/Alert";

const tabs = [
  { id: "history", title: "История вычетов" },
  { id: "operations", title: "Операции по вычетам" }
];

//хардкод
const currentTitlesForHistory = [
  "index",
  "date_start",
  "closed_ws_num",
  "remainder_operationless",
  "all_operation_sum",
  "status",
  "comment"
];

//хардкод
const currentTitlesForOperations = [
  "number",
  "time_spending_formatted",
  "money",
  "building_title",
  "event",
  "user_title"
];

const colsForOperations = {
  number: {
    title: "№"
  },
  time_spending_formatted: {
    title: "Дата операции"
  },
  money: {
    title: "Сумма"
  },
  building_title: {
    title: "Объект"
  },
  event: {
    title: "Статус-источник"
  },
  user_title: {
    title: "Автор"
  }
};

const StaffRetentionTableHistoryWindow = () => {
  const { staffRetentionListStore } = useStores();
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    setSelectedTab("history");
  }, []);

  return (
    <FormWindow
      title="Вычеты УЦ"
      subtitle={staffRetentionListStore.selectedStaffName}
      setOpenWindow={() => staffRetentionListStore.setSelectedStaffName("")}
      withoutSendData
      isNotHaveButtons
      isScroll
      isLoadingForModal={staffRetentionListStore.isLoadingForItem}
      containerClassName={styles.container}
    >
      <>
        <SwitchedTabs
          tabs={tabs}
          selectedTab={selectedTab}
          changeActiveTab={setSelectedTab}
        />
        {selectedTab === "history" ? (
          Object.values(staffRetentionListStore.selectedHoldingEdu).length ? (
            <>
              <Table className={styles.table} data-internaltable={true}>
                <thead data-internaltable={true}>
                  <tr>
                    {currentTitlesForHistory.map((title) => {
                      return (
                        <th key={title} className={styles.title}>
                          {
                            staffRetentionListStore.staffRetentionCols[title][
                              "title"
                            ]
                          }
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {Object.values(
                    staffRetentionListStore.selectedHoldingEdu
                  ).map((retention, ind) => {
                    return (
                      <tr
                        key={retention.id}
                        className={ind % 2 !== 0 ? styles.greyRow : ""}
                      >
                        {currentTitlesForHistory.map((title) => {
                          switch (title) {
                            case "date_start":
                            case "time_create":
                              return (
                                <td key={title}>
                                  <p>
                                    {getFormattedDate(
                                      retention[title].slice(0, 10)
                                    )}
                                  </p>
                                </td>
                              );
                            case "remainder_operationless":
                              return (
                                <td key={title}>
                                  <div className="d-flex flex-column">
                                    <p>
                                      {title in retention &&
                                      typeof retention[title] === "number"
                                        ? numberWithSpaces(retention[title])
                                        : ""}
                                    </p>
                                    <p className={styles.formula}>
                                      {retention.remainder_formula}
                                    </p>
                                  </div>
                                </td>
                              );
                            case "all_operation_sum":
                              return (
                                <td key={title}>
                                  <p>
                                    {retention[title]
                                      ? numberWithSpaces(
                                          Math.abs(retention[title])
                                        )
                                      : ""}
                                  </p>
                                </td>
                              );
                            case "status":
                              return (
                                <td key={title}>
                                  <div className={styles.statusColumn}>
                                    <StatusIcon
                                      icon={
                                        staffRetentionListStore.statusList[
                                          retention.status
                                        ]?.["icon"]
                                      }
                                      color={
                                        staffRetentionListStore.statusList[
                                          retention.status
                                        ]?.["color_api"]
                                      }
                                    />
                                    <p>
                                      {staffRetentionListStore.statusList[
                                        retention.status
                                      ]?.["show_title_api"]
                                        ? staffRetentionListStore.statusList[
                                            retention.status
                                          ]?.title
                                        : ""}
                                    </p>
                                  </div>
                                </td>
                              );

                            default:
                              return (
                                <td key={title}>
                                  <p
                                    className={classNames("", {
                                      [styles.comment]:
                                        title.includes("comment")
                                    })}
                                  >
                                    {retention[title]}
                                  </p>
                                </td>
                              );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className={styles.historyBlock}>
                <div className={styles.historyTitle}>История изменений</div>
                {staffRetentionListStore.selectedHoldingEdu &&
                  Object.values(staffRetentionListStore.selectedHoldingEdu)
                    .sort((a, b) => a.index - b.index)
                    .map((retention) => {
                      return (
                        <div
                          key={retention.id}
                          className={styles.retentionHistoryBlock}
                        >
                          <div className={styles.indexHolding}>
                            Вычет № {retention.index}
                          </div>

                          {retention.history &&
                            Object.entries(retention.history).map(
                              ([time, record]) => {
                                const author = Object.keys(record)[0];

                                return (
                                  <div key={time} className={styles.record}>
                                    <div className={styles.author}>
                                      <span className={styles.date}>
                                        {time}
                                      </span>{" "}
                                      {author}
                                    </div>
                                    {Object.values(record[author]).map(
                                      (item) => {
                                        return (
                                          <div
                                            key={item}
                                            className={styles.history}
                                          >
                                            {item}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              }
                            )}
                        </div>
                      );
                    })}
              </div>
            </>
          ) : (
            <Alert
              errors={{
                head: "История вычетов пуста",
                color: "empty"
              }}
              className={{ container: styles.emptyAlert }}
            />
          )
        ) : Object.values(staffRetentionListStore.selectedOperations).length ? (
          <Table className={styles.table} data-internaltable={true}>
            <thead data-internaltable={true}>
              <tr>
                {currentTitlesForOperations.map((title) => {
                  return (
                    <th key={title} className={styles.title}>
                      {colsForOperations[title]["title"]}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {Object.values(staffRetentionListStore.selectedOperations).map(
                (operation, ind) => {
                  return (
                    <tr
                      key={operation.id}
                      className={ind % 2 !== 0 ? styles.greyRow : ""}
                    >
                      {currentTitlesForOperations.map((title) => {
                        switch (title) {
                          case "number":
                            return (
                              <td key={title}>
                                <p>{ind + 1}</p>
                              </td>
                            );
                          case "money":
                            return (
                              <td key={title} className={styles.moneyColumn}>
                                <p
                                  className={
                                    operation[title] < 0
                                      ? styles.moneyRed
                                      : styles.moneyGreen
                                  }
                                >
                                  {numberWithSpaces(operation[title])}
                                </p>
                              </td>
                            );
                          case "building_title":
                            return (
                              <td key={title}>
                                <a
                                  className={styles.buildingTitle}
                                  href={`${fileUrl}/crm/building/?id=${operation["object"]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {operation[title]}
                                </a>
                                {/* <Link
                                    className={styles.buildingTitle}
                                    to={`../../building/id=${operation["object"]}/timesheet`}
                                    target="_blank"
                                  >
                                    {operation[title]}
                                  </Link> */}
                              </td>
                            );
                          case "event":
                            return (
                              <td key={title}>
                                <div className={styles.statusColumn}>
                                  <StatusIcon
                                    icon={
                                      staffRetentionListStore
                                        .buildingEventsType[
                                        operation["connect"]["event_type"]
                                      ]?.custom.icon
                                    }
                                    color={
                                      staffRetentionListStore
                                        .buildingEventsType[
                                        operation["connect"]["event_type"]
                                      ]?.custom.color_api
                                    }
                                  />
                                  {operation[title]?.["title"]}
                                </div>
                              </td>
                            );
                          case "user_title":
                            return (
                              <td
                                key={title}
                                className={styles.closedAuthorColumn}
                              >
                                <p>{operation[title]}</p>
                                <p className={styles.date}>
                                  {getFormattedDate(
                                    operation["time_create"].slice(0, 10)
                                  )}
                                </p>
                              </td>
                            );
                          default:
                            return (
                              <td key={title}>
                                <p>{operation[title]}</p>
                              </td>
                            );
                        }
                      })}
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        ) : (
          <Alert
            errors={{
              head: "Операций не было найдено",
              color: "empty"
            }}
            className={{ container: styles.emptyAlert }}
          />
        )}
      </>
    </FormWindow>
  );
};

export default observer(StaffRetentionTableHistoryWindow);
