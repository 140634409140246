import styles from "./staffOneStatusesFormStaffGuid.module.scss";
import { observer } from "mobx-react-lite";
import { useFormikContext } from "formik";
import Select from "shared/ui/Inputs/Select";
import { Status } from "stores/StaffModule/types/Status";
import { useEffect, useState } from "react";
import { StaffGuid } from "stores/StaffModule/types/StaffGuid";
import { ViewField } from "shared/ui/ViewField";
import { isEmpty } from "lodash";
import { Errors } from "stores/utils/types/ErrorsType";
import Alert from "shared/ui/Alert";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { getValues } from "shared/utils/helpers/getValues";

type StaffOneStatusesFormStaffGuidProps = {
  staffGuid: StaffGuid;
  isDismiss: boolean;
  selectStaffGuid: (uid: string, company: string, date: string) => void;
  staffGuidMessage: Record<string, Errors["message"]>;
};

const StaffOneStatusesFormStaffGuid = ({
  staffGuid,
  isDismiss,
  selectStaffGuid,
  staffGuidMessage
}: StaffOneStatusesFormStaffGuidProps) => {
  const [staffGuidList, setStaffGuidList] =
    useState<Record<string, OptionWithTitle>>();
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Status>();

  useEffect(() => {
    if (!isEmpty(staffGuid)) {
      setFieldValue(
        "staff_guid",
        staffGuid.preferred_guid === 0 || staffGuid.preferred_guid
          ? `${staffGuid.preferred_guid}`
          : null
      );
      setFieldTouched("staff_guid");

      if (isDismiss && !isEmpty(staffGuid?.list)) {
        setStaffGuidList(staffGuid.list);
      }
    }
  }, [staffGuid]);

  // в useEffect ниже для статуса уволен при изменении даты начала статуса нужно "коснуться" поля staff_guid
  // для того, чтобы отобразилось сообщение (если оно есть)
  useEffect(() => {
    if (isDismiss) {
      setTimeout(() => setFieldTouched("staff_guid"), 100);
    }
  }, [values.event_start]);

  // в useEffect ниже для статуса уволен при изменении поля Трудоустройство (staff_guid)
  // нужно проверить, заполненно ли поле staff_guid, наличие position_to_dismiss внутри staff_guid
  // и если значение dismiss_position из values не совпадает с position_to_dismiss
  // то меняем значение поля dismiss_position
  useEffect(() => {
    if (
      isDismiss &&
      staffGuid?.all_guids?.[values.staff_guid]?.position_to_dismiss &&
      values.dismiss_position !==
        staffGuid?.all_guids?.[values.staff_guid]?.position_to_dismiss
    ) {
      setFieldValue(
        "dismiss_position",
        staffGuid?.all_guids?.[values.staff_guid]?.position_to_dismiss
      );
    }
  }, [values.staff_guid]);

  const getError = () => {
    if (staffGuidMessage) {
      if (staffGuidMessage.error) {
        return staffGuidMessage.error;
      } else if (staffGuidMessage.warning) {
        return staffGuidMessage.warning;
      } else if (staffGuidMessage[values.staff_guid]) {
        return staffGuidMessage[values.staff_guid];
      } else {
        return {};
      }
    } else return {};
  };

  const error = getError();
  const staffGuidText = staffGuid?.preferred_guid
    ? staffGuid.all_guids[values.staff_guid]?.staff_code_and_position
    : staffGuid?.preferred_guid === 0
    ? staffGuid.all_guids[values.staff_guid]?.position_to_dismiss
    : null;

  if (!isDismiss || selectStaffGuid === undefined) return;

  return (
    <>
      {staffGuid?.can_change && getValues(staffGuidList).length > 1 ? (
        <Select
          name="staff_guid"
          title="Трудоустройство"
          label={
            staffGuidList[values.staff_guid]?.title?.length
              ? staffGuidList[values.staff_guid].title
              : ""
          }
          options={staffGuidList}
          valueName="id"
          required
        />
      ) : (
        <ViewField title="Трудоустройство" value={staffGuidText} disabled />
      )}
      {!isEmpty(error) && (
        <Alert errors={error} className={{ container: styles.emptyAlert }} />
      )}
    </>
  );
};

export default observer(StaffOneStatusesFormStaffGuid);
