import styles from "./phoneInput.module.scss";
import React, { useState, useRef, useEffect } from "react";
import { useFormikContext, Field, useField } from "formik";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import InputMask from "react-input-mask";
import Flag from "react-world-flags";
import PhoneInputSelect from "./PhoneInputSelect";

import {
  parsePhoneNumberFromString,
  getExampleNumber,
  AsYouType,
  CountryCode
} from "libphonenumber-js";

import { ReactComponent as IconMinus } from "shared/assets/images/mainIcons/iconMinus.svg";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { ReactComponent as IconGlobe } from "shared/assets/images/mainIcons/iconGlobe.svg";

import examples from "libphonenumber-js/mobile/examples";
import { Button, ButtonTheme } from "shared/ui/Button";
import { classNames } from "shared/utils/helpers/classNames";

type PhoneInputProps = {
  /**
   * Имя поля
   */
  name: string;
  /**
   * Индекс номера в массиве номеров
   */
  path?: number;
  /**
   * Название поля
   */
  label?: string;
  /**
   * Блокирует поле
   */
  disabled?: boolean;
  /**
   * Делает поле обязательным
   */
  required?: boolean;
  /**
   * Запрещает редактировать поле
   */
  notEditable?: boolean;
  /**
   * Источник - настройки приложения
   */
  fromAppSettings?: boolean;
  /**
   * Функция, выполняющаяся при изменении номера
   */
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    countryCallingCode: string
  ) => void;
  /**
   * Объект, содержащий стили из родителя
   */
  className?: {
    flag?: string;
    callingCode?: string;
    input?: string;
    clearBtn?: string;
    error?: string;
    select?: string;
  };
};

export const PhoneInput = ({
  name,
  label,
  disabled,
  required,
  path,
  notEditable,
  fromAppSettings,
  onChange,
  className
}: PhoneInputProps) => {
  const { dirty, initialValues, setFieldValue, setFieldTouched } =
    useFormikContext();
  const [{ value }, meta] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isOpenedSelect, setIsOpenedSelect] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(null);
  const [countryCallingCode, setCountryCallingCode] = useState("");
  const [phoneMask, setPhoneMask] = useState<{ [key: string]: string }>({});
  const [dirtyPhone, setDirtyPhone] = useState("");

  const ref = useRef<HTMLDivElement>();
  useOnClickOutside({
    ref,
    handler: () => {
      isFocused && setIsFocused(false);
      isOpenedSelect && setIsOpenedSelect(false);
    }
  });
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

  const checkValue = (isEmpty?: boolean) => {
    if (isEmpty) {
      if (typeof value === "number") return String(value).length === 0;
      else return value?.length === 0;
    } else {
      if (typeof value === "number") return String(value).length > 0;
      else return value?.length > 0;
    }
  };

  const isError = meta.error && meta.touched;
  const hasData = isFocused || checkValue();
  const isEditable = !disabled && !notEditable;

  const getPhoneMask = (country: CountryCode) => {
    const numberType = new AsYouType(country);
    numberType.input(getExampleNumber(country, examples)?.nationalNumber);

    return numberType.getTemplate().replaceAll("x", "9");
  };
  const getInitialPhone = () => {
    const initialValue =
      path !== undefined
        ? // Хранение телефонных номеров отличается в Контактах и Настройках приложения
          fromAppSettings
          ? String(initialValues["contacts"]["phones"][path]?.["number"] || "")
          : String(initialValues["phone"][path]?.["number"] || "")
        : typeof initialValues[name] === "string" ||
          typeof initialValues[name] === "number" ||
          (typeof initialValues === "object" && name in initialValues)
        ? String(initialValues[name])
        : String(value);

    if (initialValue?.length) {
      const phone = parsePhoneNumberFromString(`+${initialValue}`);

      // если номер корректный и для номера доступна страна в объекте phone
      //  или можно хотя бы предположить страну с помощью getPossibleCountries()
      if (phone && (phone.country || phone.getPossibleCountries()?.[0])) {
        // записываем в selectCountry страну, в countryCallingCode код страны,
        setSelectedCountry(phone.country || phone.getPossibleCountries()?.[0]);
        setCountryCallingCode(phone.countryCallingCode);

        // получаем маску номера
        const mask = getPhoneMask(
          phone.country || phone.getPossibleCountries()?.[0]
        );
        // и форматируем номер телефона без кода страны,
        // согласно маске - номер может быть неполный,
        // в таком случае вместо недостающих цифр будет отображаться: "_"
        const valueWithoutCountryCode = initialValue.replace(
          phone.countryCallingCode,
          ""
        );

        const newDirtyPhone: string[] = [];
        let count = 0;

        Object.values(mask).forEach((val) => {
          if (val === " " || val === "-") {
            return newDirtyPhone.push(val);
          }

          if (valueWithoutCountryCode[count]) {
            newDirtyPhone.push(valueWithoutCountryCode[count]);
          }

          count++;
        });
        setDirtyPhone(newDirtyPhone.join(""));
      }
      // если некорректный или нет страны - обнуляем страну, код страны и устанавливаем значение как есть
      else {
        setDirtyPhone(initialValue);
        setSelectedCountry(null);
        setCountryCallingCode("");
      }
    }
  };
  const handleResetNumber = () => {
    setDirtyPhone("");

    if (typeof value === "number" ? String(value).length : value) {
      setTimeout(() => {
        setFieldTouched(name);
        setFieldValue(name, "");
      }, 500);
    }

    setSelectedCountry(null);
    setCountryCallingCode("");
    setIsFocused(true);
    setIsOpenedSelect(true);
  };
  const handleSetSelectedCountry = (code: CountryCode) => {
    setDirtyPhone("");
    setFieldValue(name, "");

    setSelectedCountry(code);
    setCountryCallingCode(getExampleNumber(code, examples).countryCallingCode);

    setTimeout(() => setIsOpenedSelect(false), 200);
  };
  const handleClickOnField = () => {
    if (isEditable) {
      setIsFocused(true);
      !selectedCountry && setIsOpenedSelect(true);
      setFieldTouched(name);
      inputRef.current?.focus();
    }
  };
  const handleChangeInputValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDirtyPhone(event.target.value);
  };
  const handleValidate = (value: string) => {
    let error: string;

    if (
      (value && String(value) === countryCallingCode) ||
      (countryCallingCode &&
        (typeof value === "number" ? !String(value).length : !value?.length))
    ) {
      error = `Введите номер телефона без кода страны (+${countryCallingCode})`;
    } else if ((!value || !String(value).length) && required) {
      error = "Поле обязательно для заполнения";
    } else if (
      selectedCountry &&
      phoneMask[selectedCountry] &&
      value &&
      String(value).length !==
        `${countryCallingCode}${phoneMask[selectedCountry]
          .replaceAll("-", "")
          .replaceAll(" ", "")}`.length
    ) {
      error = "Неверно введен номер";
    } else if (
      selectedCountry &&
      phoneMask[selectedCountry] &&
      value &&
      String(value).length ===
        `${countryCallingCode}${phoneMask[selectedCountry]
          .replaceAll("-", "")
          .replaceAll(" ", "")}`.length
    ) {
      const phone = parsePhoneNumberFromString(`+${value}`);

      if ((phone && !phone.country) || !phone) {
        return "Неверно введен номер";
      }
    }
    return error;
  };
  const handleClickOnFlag = () => {
    isEditable && setIsOpenedSelect(!isOpenedSelect);
  };
  const handleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e, countryCallingCode);
  };

  // при изменении выбранной страны, если маски номера этой страны ещё нет в объекте phoneMask,
  // получаем маску номера
  useEffect(() => {
    if (selectedCountry && !phoneMask[selectedCountry]) {
      setPhoneMask({
        ...phoneMask,
        [selectedCountry]: getPhoneMask(selectedCountry)
      });
    }
  }, [selectedCountry]);
  // при изменении значения в поле ввода убираем форматирование номера по маске, добавлем код страны
  // и записываем полученное значение в fieldValue
  useEffect(() => {
    if (
      dirtyPhone?.length &&
      ((typeof value === "number" &&
        String(value).replace(countryCallingCode, "") !== dirtyPhone) ||
        (typeof value === "string" &&
          value.replace(countryCallingCode, "") !== dirtyPhone))
    ) {
      setFieldValue(
        name,
        countryCallingCode
          ? Number(
              `${countryCallingCode}${dirtyPhone
                .replaceAll("_", "")
                .replaceAll("-", "")
                .replaceAll(" ", "")}`
            )
          : ""
      );
    }
  }, [dirtyPhone]);
  // при очистке формы - форматируем исходное значение
  useEffect(() => {
    !dirty && getInitialPhone();
  }, [value]);
  // при инициализации компонента -- форматируем исходное значение
  useEffect(() => {
    getInitialPhone();

    setTimeout(() => {
      setFieldTouched(name);
    }, 500);
  }, []);

  return (
    <div
      ref={ref}
      onClick={handleClickOnField}
      id={`PhoneInput_container_${name}`}
      className={styles.relative}
    >
      <InputMask
        mask={phoneMask[selectedCountry] || null}
        value={dirtyPhone}
        onChange={handleChangeInputValue}
      >
        <div className={styles.relative}>
          <Field name={name} validate={handleValidate}>
            {() => (
              <>
                {/* Отображаем флаг страны (или иконку глобуса), если есть значение или выбрана страна или на поле фокус */}
                {dirtyPhone?.length || countryCallingCode || isFocused ? (
                  <div
                    className={classNames(
                      styles.flag,
                      {
                        [styles.notEditable]: notEditable
                      },
                      [className?.flag || ""]
                    )}
                    onClick={handleClickOnFlag}
                    id={`PhoneInput_flag_${name}`}
                  >
                    {selectedCountry ? (
                      <Flag
                        code={selectedCountry?.toLowerCase()}
                        height="24px"
                        width="20px"
                      />
                    ) : (
                      <IconGlobe className={styles.iconGlobe} />
                    )}
                    <IconExpand
                      className={classNames("", {
                        [styles.openedFlag]: isOpenedSelect,
                        [styles.closedFlag]: !isOpenedSelect
                      })}
                    />
                  </div>
                ) : null}
                {/* Если выбрана страна - отображаем код страны */}
                {selectedCountry ? (
                  <div
                    className={classNames(
                      styles.countryCallingCode,
                      {
                        [styles.notEditable]: notEditable
                      },
                      [className?.callingCode || ""]
                    )}
                  >{`+${countryCallingCode}`}</div>
                ) : null}
                {/* Поле ввода */}
                <input
                  className={classNames(
                    styles.input,
                    {
                      [styles.inputNotActive]:
                        (isOpenedSelect && !selectedCountry) || notEditable,
                      [styles.inputDisabled]: disabled,
                      [styles.inputFocused]: isFocused,
                      [styles.inputInvalid]:
                        (dirtyPhone && !selectedCountry) ||
                        (meta.touched && meta.error),
                      [styles.inputWithoutCaret]: !selectedCountry,
                      [styles.notEditable]: notEditable,
                      // для разной длины кода страны - разные отступы у input слева
                      [styles.longCode]: countryCallingCode.length === 3,
                      [styles.middleCode]: countryCallingCode.length === 2,
                      [styles.miniCode]: countryCallingCode.length === 1
                    },
                    [className?.input || ""]
                  )}
                  value={dirtyPhone || ""}
                  disabled={
                    (isOpenedSelect && !selectedCountry) ||
                    disabled ||
                    notEditable
                  }
                  onChange={handleChangeField}
                  autoComplete="no-autofill-please"
                  ref={inputRef}
                />
                {label ? (
                  <label
                    className={classNames(styles.label, {
                      [styles.labelRequired]: required,
                      [styles.labelRequiredNoValue]:
                        (!value || checkValue(true)) && !isFocused,
                      [styles.labelRequiredInvalid]: required && isError,
                      [styles.labelWithCountry]: selectedCountry && !isFocused,
                      [styles.labelWithData]: hasData
                    })}
                  >
                    {label}
                  </label>
                ) : null}
              </>
            )}
          </Field>

          {isError ? (
            <div
              className={classNames(styles.feedback, {}, [className?.error])}
            >
              {isError ? meta.error : null}
            </div>
          ) : null}
        </div>
      </InputMask>

      {isEditable && (selectedCountry || hasData) ? (
        <Button
          theme={ButtonTheme.ROUND}
          disabled={!selectedCountry}
          className={classNames(styles.clearBtn, {}, [
            className?.clearBtn || ""
          ])}
          onClick={handleResetNumber}
        >
          <IconMinus
            id={`PhoneInput_clearBtn_${name}`}
            className={classNames(styles.clearBtnImg, {
              [styles.clearBtnDisabled]: !selectedCountry
            })}
          />
        </Button>
      ) : null}

      {isOpenedSelect && (
        <PhoneInputSelect
          name={name}
          selectedCountry={selectedCountry}
          setSelectedCountry={handleSetSelectedCountry}
          inputRef={inputRef}
          className={className?.select || ""}
        />
      )}
    </div>
  );
};

export type PhoneInputAttrs = JSX.LibraryManagedAttributes<
  typeof PhoneInput,
  PhoneInputProps
>;
