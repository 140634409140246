import { object } from "yup";
import { dateSchema, stringSchema } from "shared/utils/validation/validation";

const validation = object().shape({
  start_date: dateSchema.test(
    "start_date",
    "Необходимо указать значение",
    function (val) {
      if (!this.parent.value && val) {
        return false;
      } else return true;
    }
  ),
  start_time: stringSchema
    .required("Поле обязательно для заполнения")
    .test("start_date", "Некорректно указанное время", function (val) {
      if (
        val &&
        (val.split(":")[0] > "23" ||
          val.split(":")[1] > "59" ||
          val.split(":")[1]?.length < 2)
      ) {
        return false;
      } else return true;
    }),
  value: stringSchema.test(
    "value",
    "Необходимо указать дату начала",
    function (val) {
      if (!this.parent.start_date && val) {
        return false;
      } else return true;
    }
  )
});

export default validation;
