import styles from "./staffOneSalaryRetentionUC.module.scss";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useStores } from "stores";
import StaffOneSalaryRetentionUCTable from "./StaffOneSalaryRetentionUCTable";
import Switch from "shared/ui/Inputs/Switch";
import Modal from "shared/ui/Modal";
import StaffSalaryForm from "features/StaffSalaryForm";
import LoadedComponent from "widgets/LoadedComponent";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import { ReactComponent as IconAddDisabled } from "shared/assets/images/mainIcons/iconAdd/iconAddDisabledWithoutBackground.svg";
import { Button, ButtonTheme } from "shared/ui/Button";
import { classNames } from "shared/utils/helpers/classNames";

const StaffOneSalaryRetentionUC = () => {
  const { staffOneSalaryStore, staffOneStore } = useStores();

  const { id } = useParams();
  const [isOpenModalLocal, setIsOpenModalLocal] = useState(false);
  const [isOpenClarificationWindow, setIsOpenClarificationWindow] =
    useState(false);

  useEffect(() => {
    !(id in staffOneSalaryStore.staffRetention) &&
      staffOneSalaryStore.getWithholdingEdu(id);
  }, [id]);

  // эту строчку пока оставить, без нее список ошибок не отображается
  // баг связанный с mobx, исправить в будущем
  staffOneSalaryStore.errorsMessage[id];

  return (
    <LoadedComponent
      isLoading={staffOneSalaryStore.isLoadingHoldingEdu[id]}
      withoutText
    >
      <Formik
        initialValues={{
          without_edu: staffOneStore.info["without_edu"] ?? 0
        }}
        onSubmit={() => {
          return;
        }}
      >
        {({ values, setFieldTouched, setFieldValue, handleReset }) => {
          const openAddCompensationWindow = () => {
            if (!values.without_edu) {
              setIsOpenModalLocal(true);
              staffOneSalaryStore.setIsOpenModal(true);
            }
          };
          const handleChangeWithoutEduOpeningForm = () => {
            if (!values.without_edu) {
              setFieldValue("without_edu", 1);
            } else {
              setFieldValue("without_edu", 0);
            }
            setIsOpenClarificationWindow(true);
            setFieldTouched("without_edu");
          };
          const closeClarificationWindow = () => {
            setIsOpenClarificationWindow(false);
            handleReset();
          };
          const toggleSetWithoutEdu = () => {
            staffOneSalaryStore.setWithoutEdu(
              id,
              values.without_edu ? "set" : "unset"
            );
            setIsOpenClarificationWindow(false);
          };
          const closeModalWindow = () => {
            setIsOpenModalLocal(false);
          };
          const clearErrorsMessage = (id: string) => () =>
            staffOneSalaryStore.clearErrorsMessage(id);

          return (
            <>
              <div className={styles.buttonsRow}>
                <Button
                  className={styles.addBtn}
                  theme={ButtonTheme.CLEAR}
                  type="button"
                  onClick={openAddCompensationWindow}
                  disabled={Boolean(values.without_edu)}
                  id="StaffOneSalaryRetentionUC_addCompensation"
                >
                  Добавить вычет
                  {values.without_edu ? <IconAddDisabled /> : <IconAdd />}
                </Button>
                <label className={styles.switch}>
                  <p
                    className={classNames(styles.withoutUC, {
                      [styles.withoutUCDisabled]: Object.keys(
                        staffOneSalaryStore.staffRetention[id]?.["widget"] || {}
                      ).length
                    })}
                  >
                    Без УЦ
                  </p>
                  <Switch
                    name="without_edu"
                    onChange={handleChangeWithoutEduOpeningForm}
                    disabled={
                      Object.keys(
                        staffOneSalaryStore.staffRetention[id]?.["widget"] || {}
                      ).length !== 0
                    }
                  />
                </label>
              </div>
              <StaffOneSalaryRetentionUCTable />
              <StaffSalaryForm
                title="Добавление вычета УЦ"
                isOpenModal={isOpenModalLocal}
                setIsOpenModal={closeModalWindow}
                clearErrorsMessage={clearErrorsMessage(id)}
                saveBtnOnClickFunc={staffOneSalaryStore.addHoldingEdu}
                fields={["date_start", "money", "ws_num", "comment"]}
                disabledFields={["money", "ws_num"]}
                nameOneOfStaff={staffOneSalaryStore.nameOneOfStaff[id]}
                type="add"
                isHoldingEdu
                errorsMessage={staffOneSalaryStore.errorsMessage[id]}
                money={staffOneSalaryStore.money}
                ws_num={staffOneSalaryStore.ws_num}
                saveSuccessModal={staffOneSalaryStore.success[id]}
                onSuccessModalClose={() => {
                  staffOneSalaryStore.setSuccess(id, false);
                }}
                isLoadingForModal={staffOneSalaryStore.isLoadingForForm[id]}
              />

              {!isOpenModalLocal && isOpenClarificationWindow ? (
                <Modal
                  type="clarification"
                  title={`${
                    values.without_edu
                      ? "Проставить сотруднику"
                      : "Снять у сотрудника"
                  } признак "Без УЦ"?`}
                  show={isOpenClarificationWindow}
                  blueBtnTitle="Отмена"
                  btnWithCrossTitle={
                    values.without_edu ? "Проставить" : "Снять"
                  }
                  btnWithCrossOnClick={toggleSetWithoutEdu}
                  blueBtnOnClick={closeClarificationWindow}
                  onHide={closeClarificationWindow}
                  className={styles.modal}
                />
              ) : null}
            </>
          );
        }}
      </Formik>
    </LoadedComponent>
  );
};

export default observer(StaffOneSalaryRetentionUC);
